import actions from './actions';

const {
  DELETE_ERROR_LOG_BEGIN,
  DELETE_ERROR_LOG_FAILED,
  DELETE_ERROR_LOG_SUCCESS,
  GET_ERROR_LOG_BEGIN,
  GET_ERROR_LOG_FAILED,
  GET_ERROR_LOG_SUCCESS,
  DELETE_MULTIPLE_LOG_BEGIN,
  DELETE_MULTIPLE_LOG_FAILED,
  DELETE_MULTIPLE_LOG_SUCCESS,
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  errorLogs: [],
  loading: false,
  error: null,
};

const ErrorLogReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ERROR_LOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ERROR_LOG_SUCCESS:
      return {
        ...state,
        total: data.total,
        errorLogs: data.errorLogs,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_ERROR_LOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_ERROR_LOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ERROR_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ERROR_LOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_MULTIPLE_LOG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MULTIPLE_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        data,
      };
    case DELETE_MULTIPLE_LOG_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default ErrorLogReducer;
