import actions from './actions';

const {
  GET_SINGLE_TOOLS_PAGE_CONTENT_BEGIN,
  GET_SINGLE_TOOLS_PAGE_CONTENT_FAILED,
  GET_SINGLE_TOOLS_PAGE_CONTENT_SUCCESS,
  GET_TOOLS_PAGES_CONTENT_BEGIN,
  GET_TOOLS_PAGES_CONTENT_FAILED,
  GET_TOOLS_PAGES_CONTENT_SUCCESS,
  UPDATE_TOOLS_PAGE_CONTENT_BEGIN,
  UPDATE_TOOLS_PAGE_CONTENT_FAILED,
  UPDATE_TOOLS_PAGE_CONTENT_SUCCESS,
} = actions;

const initState = {
  toolsPageContents: [],
  currentToolPageContent: null,
  currentPage: 1,
  pageLimit: 9,
  loading: false,
  error: null,
};

const ToolsPagesContentReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_TOOLS_PAGES_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOOLS_PAGES_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        toolsPageContents: data,
      };
    case GET_TOOLS_PAGES_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        err,
      };
    case GET_SINGLE_TOOLS_PAGE_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_TOOLS_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentToolPageContent: data,
      };
    case GET_SINGLE_TOOLS_PAGE_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        err,
      };
    case UPDATE_TOOLS_PAGE_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
        err,
      };
    case UPDATE_TOOLS_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentToolPageContent: data,
      };
    case UPDATE_TOOLS_PAGE_CONTENT_FAILED:
      return {
        ...state,
        loading: false,
        err,
      };
    default:
      return state;
  }
};

export default ToolsPagesContentReducer;
