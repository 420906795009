import * as Yup from 'yup';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicPageFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`description${item[1]}`]: '',
    };
  });

  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicPageFields();
  const initialFormData = {
    isMultiLang: 'false',
    title: '',
    ...dynamicFields,
  };

  return initialFormData;
}

export const toolsPageInitialFormData = getInitialFormData();

export function toolsPageValidationSchema(isMultiLang) {
  let validationFields = {
    [`description${DEFAULT_LANG.value}`]: Yup.string().required(`Description(${DEFAULT_LANG.label}) is required!`),
  };
  if (isMultiLang) {
    LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
      validationFields = {
        ...validationFields,
        [`description${value}`]: Yup.string().required(`Description(${label}) is required!`),
      };
    });
  }
  return Yup.object().shape({
    isMultiLang: Yup.string(),
    name: Yup.string(),
    ...validationFields,
  });
}
