import * as Yup from 'yup';

export const fuelPriceSchema = Yup.object().shape({
  effectiveDate: Yup.date('Effective Date must be a Valid Date').required('Effective Date is Required'),
  fuelPrices: Yup.array().of(
    Yup.object().shape({
      fuelType: Yup.string().required('Fuel Type is Required'),
      inlandPrice: Yup.number()
        .typeError('Fuel Price must be a number')
        .positive('Fuel Price must be greater then zero')
        .required('Fuel Price is Required'),
      coastalPrice: Yup.number()
        .typeError('Fuel Price must be a number')
        .nullable(),
    }),
  ),
});
