import { message } from 'antd';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getTechnicalDocsBegin,
  getTechnicalDocsFail,
  getTechnicalDocsSuccess,
  addTechnicalDocsBegin,
  addTechnicalDocsFail,
  addTechnicalDocsSuccess,
  updateTechnicalDocsBegin,
  updateTechnicalDocsFail,
  updateTechnicalDocsSuccess,
  deleteTechnicalDocsBegin,
  deleteTechnicalDocsFail,
  deleteTechnicalDocsSuccess,
} = actions;

let cancelToken;
export const getTechnicalDocs = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getTechnicalDocsBegin());
    const res = await axios.get(`technical-docs${filter || ''}`, {
      headers: { ...getHeaders() },
    });
    dispatch(getTechnicalDocsSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(getTechnicalDocsFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

export const addTechnicalDocs = data => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addTechnicalDocsBegin());
    const res = await axios.post('technical-docs', data, { headers: { ...getHeaders() } });
    const newTechnicalDocs = [res.data, ...state.technicalDocs.technicalDocs];

    dispatch(addTechnicalDocsSuccess(newTechnicalDocs));
    message.success('Technical Document added successfully');
    return res;
  } catch (err) {
    dispatch(addTechnicalDocsFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, true));
    return err;
  }
};

export const updateTechnicalDocs = (id, data) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateTechnicalDocsBegin());
    const res = await axios.put(`technical-docs/${id}`, data, {
      headers: { ...getHeaders() },
    });
    const updatedTechnidalDocs = state.technicalDocs.technicalDocs.map(technicalDoc => {
      if (technicalDoc._id === id) return res.data;
      return technicalDoc;
    });
    dispatch(updateTechnicalDocsSuccess(updatedTechnidalDocs));
    message.success('Technical Document updated successfully');
    return res;
  } catch (err) {
    dispatch(updateTechnicalDocsFail(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

export const deleteTechnicalDocs = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteTechnicalDocsBegin());
    const res = await axios.delete(`technical-docs/${id}`, { headers: { ...getHeaders() } });
    dispatch(
      deleteTechnicalDocsSuccess(state.technicalDocs.technicalDocs.filter(technicalDoc => technicalDoc._id !== id)),
    );
    message.success('Technical Document deleted successfully');
    return res;
  } catch (err) {
    dispatch(deleteTechnicalDocsFail(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};
