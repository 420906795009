const actions = {
  GET_ERROR_LOG_BEGIN: 'GET_ERROR_LOG_BEGIN',
  GET_ERROR_LOG_SUCCESS: 'GET_ERROR_LOG_SUCCESS',
  GET_ERROR_LOG_FAILED: 'GET_ERROR_LOG_FAILED',

  DELETE_ERROR_LOG_BEGIN: 'DELETE_ERROR_LOG_BEGIN',
  DELETE_ERROR_LOG_SUCCESS: 'DELETE_ERROR_LOG_SUCCESS',
  DELETE_ERROR_LOG_FAILED: 'DELETE_ERROR_LOG_FAILED',

  DELETE_MULTIPLE_LOG_BEGIN: 'DELETE_MULTIPLE_LOG_BEGIN',
  DELETE_MULTIPLE_LOG_SUCCESS: 'DELETE_MULTIPLE_LOG_SUCCESS',
  DELETE_MULTIPLE_LOG_FAILED: 'DELETE_MULTIPLE_LOG_FAILED',

  getErrorLogBegin: () => {
    return {
      type: actions.GET_ERROR_LOG_BEGIN,
    };
  },

  getErrorLogSuccess: data => {
    return {
      type: actions.GET_ERROR_LOG_SUCCESS,
      data,
    };
  },

  getErrorLogFailed: err => {
    return {
      type: actions.GET_ERROR_LOG_FAILED,
      err,
    };
  },

  deleteErrorLogBegin: () => {
    return {
      type: actions.DELETE_ERROR_LOG_BEGIN,
    };
  },

  deleteErrorLogSuccess: data => {
    return {
      type: actions.DELETE_ERROR_LOG_SUCCESS,
      data,
    };
  },

  deleteErrorLogFailed: err => {
    return {
      type: actions.DELETE_ERROR_LOG_FAILED,
      err,
    };
  },

  deleteMultipleErrorLogBegin: () => {
    return {
      type: actions.DELETE_MULTIPLE_LOG_BEGIN,
    };
  },

  deleteMultipleErrorLogSuccess: data => {
    return {
      type: actions.DELETE_MULTIPLE_LOG_SUCCESS,
      data,
    };
  },

  deleteMultipleErrorLogFailed: err => {
    return {
      type: actions.DELETE_MULTIPLE_LOG_FAILED,
      err,
    };
  },
};

export default actions;
