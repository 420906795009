import actions from './actions';
import axios, { CancelToken } from '../axios';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getPromoCarsBegin,
  getPromoCarsFail,
  getPromoCarsSuccess,
  removePromoCarFail,
  removePromoCarsBegin,
  removePromoCarsSuccess,
  reorderPromoCarsBegin,
  reorderPromoCarsFail,
  reorderPromoCarsSuccess,
} = actions;

let cancelToken;
export const getPromoCars = () => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getPromoCarsBegin());
    const res = await axios.get('promo-cars', { headers: { ...getHeaders() }, cancelToken: cancelToken?.token });
    dispatch(getPromoCarsSuccess(res.data.data));
    return res;
  } catch (err) {
    if (err?.response?.status === 404) {
      dispatch(getPromoCarsSuccess([]));
      return err;
    }
    dispatch(getPromoCarsFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

export const removePromoCars = car_ids => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(removePromoCarsBegin());
    const res = await axios.post(
      `promo-cars`,
      { car_ids },
      { headers: { ...getHeaders() }, cancelToken: cancelToken?.token },
    );
    dispatch(removePromoCarsSuccess());
    return res;
  } catch (err) {
    dispatch(removePromoCarFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

export const reOrderPromoCars = data => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(reorderPromoCarsBegin());
    dispatch(reorderPromoCarsSuccess(data));
    const res = await axios.post(
      'promo-cars/re-order',
      { promoCarList: data },
      { headers: { ...getHeaders() }, cancelToken: cancelToken?.token },
    );

    return res;
  } catch (err) {
    dispatch(reorderPromoCarsFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};
