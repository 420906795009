import React from 'react';
import { Modal } from '../../components/modals/antd-modals';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';

const DeleteFuelPriceModal = ({ visible, onCancel, handleDelete, fuelPrice }) => {
  return fuelPrice ? (
    <Modal
      visible={visible}
      type="primary"
      title="Delete Fuel Price"
      onCancel={onCancel}
      footer={[
        <div key="date-fuel-price" className="car-modal-footer">
          <Button size="default" type="danger" key="submit" onClick={() => handleDelete(fuelPrice._id)}>
            Delete Fuel Price
          </Button>
          <Button size="default" type="white" key="black" outlined="true" onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
    >
      <div className="car-modal">
        <BasicFormWrapper>
          <p>Delete Fuel Price ?</p>
        </BasicFormWrapper>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default DeleteFuelPriceModal;
