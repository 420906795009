import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Select, Spin } from 'antd';
//
import TopCarSalesTable from './TopCarSalesTable';
import AddTopCarSale from './AddTopCarSale';
import {
  addTopCarSales,
  deleteTopCarSale,
  editTopCarSale,
  getTopCarSales,
} from '../../redux/topCarSales/actionCreator';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import { getAllBrands } from '../../redux/carbrand/actionCreator';

const TopCarSales = () => {
  const dispatch = useDispatch();

  const [queryState, setQueryState] = useState({ visible: false, currentPage: 1, pageLimit: 10 });

  const showModal = () => setQueryState(prev => ({ ...prev, visible: true }));

  const onCancel = () => setQueryState(prev => ({ ...prev, visible: false }));

  const handleAdd = async (formData, resetForm) => {
    const carSales = formData.car_sales.map(sale => ({
      ...sale,
      sold: sale.sold || undefined, // change null and empty string to undefined  api only accepts undefined
    }));
    const res = await dispatch(addTopCarSales({ ...formData, car_sales: carSales }));
    if (res?.status === 201) {
      resetForm();
      setQueryState(prev => ({ ...prev, visible: false }));
      dispatch(getTopCarSales(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };

  const handleEdit = async (id, data) => {
    //
    const carSales = data.car_sales.map(sale => ({
      ...sale,
      sold: sale.sold || undefined, // change null and empty string to undefined  api only accepts undefined
    }));
    const res = await dispatch(editTopCarSale(id, { ...data, car_sales: carSales }));
    if (res?.status === 200) dispatch(getTopCarSales(`?page=${queryState.currentPage}$limit=${queryState.pageLimit}`));
  };

  const handleDelete = async id => {
    const res = await dispatch(deleteTopCarSale(id));
    if (res?.body?.status === 200)
      dispatch(getTopCarSales(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  };
  const { topCarSales, currentPage, pageLimit, total, loading, brands } = useSelector(state => {
    return {
      brands: state.carBrand.brands,
      models: state.carModel.modelsByBrand,
      topCarSales: state.topCarSales.topCarSales,
      currentPage: state.topCarSales.currentPage,
      pageLimit: state.topCarSales.pageLimit,
      total: state.topCarSales.total,
      loading: state.topCarSales.loading,
    };
  });
  useEffect(() => {
    dispatch(getTopCarSales(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  }, [queryState.currentPage, queryState.pageLimit]);

  useEffect(() => {
    if (brands.length === 0) dispatch(getAllBrands());
  }, []);

  return (
    <>
      <PageHeader
        ghost
        title="Top Car Sales"
        subTitle={total > 0 && <span className="title-counter">{total} Top Car Sales record </span>}
        buttons={[
          <Button onClick={showModal} key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
            Add New Car Sales
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <TopCarSalesTable
                currentPage={currentPage}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                pageLimit={pageLimit}
                setParentState={setQueryState}
                topCarSales={topCarSales}
                total={total}
              />
            )}
          </Col>
        </Row>
        <AddTopCarSale visible={queryState.visible} onCancel={onCancel} handleSubmit={handleAdd} />
      </Main>
    </>
  );
};

export default TopCarSales;
