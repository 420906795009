const actions = {
  GET_FUEL_PRICES_BEGIN: 'GET_FUEL_PRICES_BEGIN',
  GET_FUEL_PRICES_SUCCESS: 'GET_FUEL_PRICES_SUCCESS',
  GET_FUEL_PRICES_FAILED: 'GET_FUEL_PRICES_FAILED',

  ADD_FUEL_PRICE_BEGIN: 'ADD_FUEL_PRICE_BEGIN',
  ADD_FUEL_PRICE_SUCCESS: 'ADD_FUEL_PRICE_SUCCESS',
  ADD_FUEL_PRICE_FAILED: 'ADD_FUEL_PRICE_FAILED',

  UPDATE_FUEL_PRICE_BEGIN: 'UPDATE_FUEL_PRICE_BEGIN',
  UPDATE_FUEL_PRICE_SUCCESS: 'UPDATE_FUEL_PRICE_SUCCESS',
  UPDATE_FUEL_PRICE_FAILED: 'UPDATE_FUEL_PRICE_FAILED',

  DELETE_FUEL_PRICE_BEGIN: 'DELETE_FUEL_PRICE_BEGIN',
  DELETE_FUEL_PRICE_SUCCESS: 'DELETE_FUEL_PRICE_SUCCESS',
  DELETE_FUEL_PRICE_FAILED: 'DELETE_FUEL_PRICE_FAILED',

  getFuelPricesBegin: () => {
    return {
      type: actions.GET_FUEL_PRICES_BEGIN,
    };
  },

  getFuelPricesSuccess: data => {
    return {
      type: actions.GET_FUEL_PRICES_SUCCESS,
      data,
    };
  },
  getFuelPricesFail: err => {
    return {
      type: actions.GET_FUEL_PRICES_FAILED,
      err,
    };
  },
  addFuelPriceBegin: () => {
    return {
      type: actions.ADD_FUEL_PRICE_BEGIN,
    };
  },
  addFuelPriceSuccess: data => {
    return {
      type: actions.ADD_FUEL_PRICE_SUCCESS,
      data,
    };
  },
  addFuelPriceFailed: err => {
    return {
      type: actions.ADD_FUEL_PRICE_FAILED,
      err,
    };
  },
  updateFuelPriceBegin: () => {
    return {
      type: actions.UPDATE_FUEL_PRICE_BEGIN,
    };
  },
  updateFuelPriceSuccess: data => {
    return {
      type: actions.UPDATE_FUEL_PRICE_SUCCESS,
      data,
    };
  },
  updateFuelPriceFailed: err => {
    return {
      type: actions.UPDATE_FUEL_PRICE_FAILED,
      err,
    };
  },
  deleteFuelPriceBegin: () => {
    return {
      type: actions.DELETE_FUEL_PRICE_BEGIN,
    };
  },
  deleteFuelPriceSuccess: data => {
    return {
      type: actions.DELETE_FUEL_PRICE_SUCCESS,
      data,
    };
  },
  deleteFuelPriceFailed: err => {
    return {
      type: actions.DELETE_FUEL_PRICE_FAILED,
      err,
    };
  },
};

export default actions;
