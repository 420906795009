/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import CarForm from './CarForm';
import { carInitialFormData } from './car.schema';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';

import { Button } from '../../../components/buttons/buttons';
import { getAllCars } from '../../../redux/car/actionCreator';
import { DEFAULT_LANG, IMAGE_URL, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import { getAllModelsByBrand } from '../../../redux/carmodel/actionCreator';

const EditCar = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { carsData, userType } = useSelector(state => {
    return {
      carsData: state.car.cars,
      carLoading: state.car.loading,
      userType: state.auth.user.role.name,
    };
  });

  const [formData, setFormData] = useState({ ...carInitialFormData });

  useEffect(() => {
    if (carsData.length === 0) {
      dispatch(getAllCars());
    }
  }, []);

  useEffect(() => {
    if (carsData && carsData.length > 0 && match) {
      const carEditDataById = carsData.find(item => item?._id === match?.params?.id);

      let descriptionFields = {};
      if (carEditDataById?.details.length > 1) {
        // eslint-disable-next-line no-unused-vars
        LANGUAGE_BY_DOMAIN.forEach((langItem, index) => {
          descriptionFields = {
            ...descriptionFields,
            [`description${langItem[1]}`]:
              langItem[1] === DEFAULT_LANG.value && carEditDataById?.details.length < 2
                ? carEditDataById?.details[0]?.description
                : carEditDataById?.details[index]?.description ?? '',
          };
        });
      } else {
        descriptionFields = {
          ...descriptionFields,
          [`description${DEFAULT_LANG.value}`]: carEditDataById?.details[0]?.description ?? '',
        };
      }

      let filteredCarEditDataById = {};

      Object.entries(carEditDataById).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
          filteredCarEditDataById = {
            ...filteredCarEditDataById,
            [key]: String(value),
          };
          return;
        }
        if (typeof value === 'number' || value) {
          filteredCarEditDataById = {
            ...filteredCarEditDataById,
            [key]: value ?? '',
          };
        }
      });

      const getFiles = keyName => {
        if (keyName === 'carIssues') {
          const fileArr = JSON.parse(carEditDataById[keyName]);
          if (Array.isArray(fileArr))
            return fileArr.map((item, index) => {
              return {
                issueCaption: item?.caption,
                issueImage: [
                  {
                    uid: index + 1,
                    name: `${item?.file}`,
                    status: 'done',
                    url: `${IMAGE_URL}/${item?.file}`,
                  },
                ],
              };
            });
          return [];
        }
        if (keyName === 'images') {
          const fileArr = JSON.parse(carEditDataById[keyName]);
          if (Array.isArray(fileArr))
            return fileArr.map((item, index) => {
              return {
                uid: index + 1,
                name: `${item}`,
                status: 'done',
                url: `${IMAGE_URL}/${item}`,
              };
            });
          return [];
        }

        return carEditDataById[keyName]
          ? [
              {
                uid: '-1',
                name: `${carEditDataById[keyName]}`,
                status: 'done',
                url: `${IMAGE_URL}/${carEditDataById[keyName]}`,
              },
            ]
          : [];
      };

      const editFormData = {
        ...filteredCarEditDataById,
        model: carEditDataById.model?._id,
        brand: carEditDataById.brand?._id,
        bodyType: carEditDataById.bodyType?._id,
        store: carEditDataById.store?._id,
        exteriorColor: carEditDataById.exteriorColor?._id,
        interiorColor: carEditDataById.interiorColor?._id,
        length: carEditDataById.dimension?.length ?? '',
        breadth: carEditDataById.dimension?.breadth ?? '',
        height: carEditDataById.dimension?.height ?? '',
        dateFirstUse: carEditDataById.dateFirstUse ? moment(carEditDataById.dateFirstUse).format('YYYY-MM-DD') : null,
        isMultiLang: carEditDataById?.details && carEditDataById?.details.length > 1 ? 'true' : 'false',
        // carIssueArr: getFiles('carIssues'),
        images: getFiles('images'),
        keys: getFiles('keys'),
        technicalVisitCertificate: getFiles('technicalVisitCertificate'),
        audio: getFiles('audio'),
        ...descriptionFields,
      };

      [
        'carIssues',
        'createdAt',
        'details',
        'dimension',
        'inspectionReport',
        'monthlyInstallment',
        'name',
        'slug',
        'uId',
        'updatedAt',
      ].forEach(item => {
        delete editFormData[item];
      });

      setFormData({
        ...formData,
        ...editFormData,
      });
    }
  }, [carsData]);

  useEffect(() => {
    if (carsData && carsData.length > 0) {
      const carEditDataById = carsData.find(item => item?._id === match?.params?.id);
      dispatch(getAllModelsByBrand(carEditDataById.brand?._id));
    }
  }, [carsData]);

  return (
    <>
      <PageHeader
        ghost
        title="Update Car"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/cars`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <div className="body-type-modal">
            <CarForm formData={formData} setFormData={setFormData} />
          </div>
        </Cards>
      </Main>
    </>
  );
};

EditCar.propTypes = {
  match: propTypes.string,
};

export default EditCar;
