import React, { useState } from 'react';
import { Checkbox, Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
//
import DeleteErrorLogModal from './DeleteErrorLogModal';
import ViewErrorLogModal from './ViewErrorLogModal';
import { UserTableStyleWrapper } from '../pages/style';
import { TableWrapper } from '../styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';

const ErrorLogColumns = [
  {
    title: '',
    dataIndex: 'checkbox',
    key: 'checkbox',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Severity',
    dataIndex: 'severity',
    key: 'severity',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const ErrorLogTable = ({
  errorLogs,
  handleDelete,
  total,
  pageLimit,
  currentPage,
  setParentState,
  setSelectedErrorLogs,
  selectedErrorLogs,
}) => {
  const [state, setState] = useState({ deleteModelVisible: false, viewModelVisible: false });
  const [errorLog, setErrorLog] = useState({});

  const showModal = (id, type) => {
    const selectedErrorLog = errorLogs.find(log => log._id === id);
    if (selectedErrorLog) {
      if (type === 'delete') setState({ ...state, deleteModelVisible: true });
      else setState({ ...state, viewModelVisible: true });
    }
    setErrorLog(selectedErrorLog);
  };

  const handleCancel = () => {
    setErrorLog({});
    setState({ deleteModelVisible: false, viewModelVisible: false });
  };

  const handleDeleteConfirmation = (errorId, formData) => {
    handleDelete(errorId, formData);
    setErrorLog(null);
    setState({ deleteModelVisible: false, viewModelVisible: false });
  };

  const handleLogCheck = logId => {
    const logIndex = selectedErrorLogs.findIndex(el => el === logId);
    const temp = [...selectedErrorLogs];
    if (logIndex === -1) temp.push(logId);
    else temp.slice(logIndex, 1);
    setSelectedErrorLogs(temp);
  };

  const errorLogTypes = [];

  errorLogs?.map((log, index) => {
    const { _id, severity, message, createdAt } = log;
    return errorLogTypes.push({
      key: _id,
      checkbox: (
        <Checkbox
          name="error-log-action"
          checked={selectedErrorLogs.includes(_id)}
          onChange={() => handleLogCheck(_id)}
        />
      ),
      message: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '500px', textWrap: 'nowrap' }}>
              {message}
            </span>
          </Heading>
        </div>
      ),
      date: <span>{moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}</span>,
      severity: <div style={{ textAlign: 'center' }}>{severity}</div>,
      action: (
        <div className="table-actions">
          <Button className="icon-link" type="info" shape="circle" onClick={() => showModal(_id, 'view')}>
            <FeatherIcon icon="eye" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => showModal(_id, 'delete')}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    });
  });

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={errorLogTypes}
            columns={ErrorLogColumns}
            pagination={{
              current: parseInt(currentPage, 10),
              defaultPageSize: pageLimit,
              total,
              showSizeChanger: true,
            }}
            onChange={data =>
              setParentState(prev => ({ ...prev, currentPage: data.current, pageLimit: data.pageSize }))
            }
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      {errorLogs.length > 0 && (
        <>
          {state.viewModelVisible && errorLog && (
            <ViewErrorLogModal errorLog={errorLog} onCancel={handleCancel} visible={state.viewModelVisible} />
          )}
          {state.deleteModelVisible && errorLog && (
            <DeleteErrorLogModal
              visible={state.deleteModelVisible}
              onCancel={handleCancel}
              errorLog={errorLog}
              handleDelete={handleDeleteConfirmation}
            />
          )}
        </>
      )}
    </Cards>
  );
};

export default ErrorLogTable;
