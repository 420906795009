import React, { createContext, useContext } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { UserTableStyleWrapper } from '../pages/style';
import { TableWrapper } from '../styled';
//

const Columns = [
  {
    title: 'SN',
    dataIndex: 'sNo',
    key: 'sNo',
  },
  {
    title: 'Page Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];

const ToolsPageContentTable = ({ toolsPageContents }) => {
  const newToolsPageContentTableTypes = [];

  toolsPageContents?.map((toolContent, index) => {
    const { _id, name, destination } = toolContent;
    return newToolsPageContentTableTypes.push({
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      name: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {name}
          </Heading>
        </div>
      ),
      actions: (
        <div className="table-actions">
          <Link to={`/admin/tools-page-content/${destination}`}>
            <Button className="btn-icon" type="danger" to="#" shape="circle">
              <FeatherIcon icon="eye" size={16} />
            </Button>
          </Link>
          <Link to={`/admin/tools-page-content/edit/${destination}`}>
            <Button className="btn-icon" type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
          </Link>
        </div>
      ),
    });
  });


  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table dataSource={newToolsPageContentTableTypes} columns={Columns} pagination={false} />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  );
};

export default ToolsPageContentTable;
