const actions = {
  GET_TECHNICAL_DOCS_BEGIN: 'GET_TECHNICAL_DOCS_BEGIN',
  GET_TECHNICAL_DOCS_SUCCESS: 'GET_TECHNICAL_DOCS_SUCCESS',
  GET_TECHNICAL_DOCS_FAILED: 'GET_TECHNICAL_DOCS_FAILED',

  ADD_TECHNICAL_DOCS_BEGIN: 'ADD_TECHNICAL_DOCS_BEGIN',
  ADD_TECHNICAL_DOCS_SUCCESS: 'ADD_TECHNICAL_DOCS_SUCCESS',
  ADD_TECHNICAL_DOCS_FAILED: 'ADD_TECHNICAL_DOCS_FAILED',

  UPDATE_TECHNICAL_DOCS_BEGIN: 'UPDATE_TECHNICAL_DOCS_BEGIN',
  UPDATE_TECHNICAL_DOCS_SUCCESS: 'UPDATE_TECHNICAL_DOCS_SUCCESS',
  UPDATE_TECHNICAL_DOCS_FAILED: 'UPDATE_TECHNICAL_DOCS_FAILED',

  DELETE_TECHNICAL_DOCS_BEGIN: 'DELETE_TECHNICAL_DOCS_BEGIN',
  DELETE_TECHNICAL_DOCS_SUCCESS: 'DELETE_TECHNICAL_DOCS_SUCCESS',
  DELETE_TECHNICAL_DOCS_FAILED: 'DELETE_TECHNICAL_DOCS_FAILED',

  getTechnicalDocsBegin: () => {
    return {
      type: actions.GET_TECHNICAL_DOCS_BEGIN,
    };
  },

  getTechnicalDocsSuccess: data => {
    return {
      type: actions.GET_TECHNICAL_DOCS_SUCCESS,
      data,
    };
  },

  getTechnicalDocsFail: err => {
    return {
      type: actions.GET_TECHNICAL_DOCS_FAILED,
      err,
    };
  },

  addTechnicalDocsBegin: () => {
    return {
      type: actions.ADD_TECHNICAL_DOCS_BEGIN,
    };
  },

  addTechnicalDocsSuccess: data => {
    return {
      type: actions.ADD_TECHNICAL_DOCS_SUCCESS,
      data,
    };
  },

  addTechnicalDocsFail: err => {
    return {
      type: actions.ADD_TECHNICAL_DOCS_FAILED,
      err,
    };
  },

  updateTechnicalDocsBegin: () => {
    return {
      type: actions.UPDATE_TECHNICAL_DOCS_BEGIN,
    };
  },

  updateTechnicalDocsSuccess: data => {
    return {
      type: actions.UPDATE_TECHNICAL_DOCS_SUCCESS,
      data,
    };
  },

  updateTechnicalDocsFail: err => {
    return {
      type: actions.UPDATE_TECHNICAL_DOCS_FAILED,
      err,
    };
  },

  deleteTechnicalDocsBegin: () => {
    return {
      type: actions.DELETE_TECHNICAL_DOCS_BEGIN,
    };
  },

  deleteTechnicalDocsSuccess: data => {
    return {
      type: actions.DELETE_TECHNICAL_DOCS_SUCCESS,
      data,
    };
  },

  deleteTechnicalDocsFail: err => {
    return {
      type: actions.DELETE_TECHNICAL_DOCS_FAILED,
      err,
    };
  },
};

export default actions;
