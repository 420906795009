import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
//
import PromoCarTable from './overview/PromoCarTable';
import { Main } from '../styled';
import { getPromoCars } from '../../redux/promoCars/actionCreator';
import { PageHeader } from '../../components/page-headers/page-headers';
import { removeCarsFromPromoList } from '../../redux/car/actionCreator';

const PromoCarsList = () => {
  const dispatch = useDispatch();
  const [selectedCars, setSelectedCars] = useState([]);
  const { loading, promoCars } = useSelector(state => {
    return {
      promoCars: state.promoCars.promoCars,
      loading: state.promoCars.loading,
    };
  });

  const handleRemovePromoCars = async () => {
    const res = await dispatch(removeCarsFromPromoList(selectedCars));
    if (res.status === 200) {
      setSelectedCars([]);
      dispatch(getPromoCars());
    }
  };

  useEffect(() => {
    dispatch(getPromoCars());
  }, []);
  return (
    <>
      <PageHeader
        ghost
        title="Promo Cars"
        subTitle={`${promoCars.length} Promo Cars`}
        buttons={[
          <Button
            onClick={handleRemovePromoCars}
            key="1"
            style={selectedCars.length === 0 ? {} : { backgroundColor: '#155BDC', color: '#fff' }}
            size="default"
            disabled={selectedCars.length === 0}
          >
            Remove Cars
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <PromoCarTable selectedCars={selectedCars} setSelectedCars={setSelectedCars} promoCars={promoCars} />
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default PromoCarsList;
