import React, { useEffect, useState } from 'react';
import { Row, Spin, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import Pluralize from 'pluralize';
//
import TechnicalDocsTable from './TechnicalDocsTable';
import AddTechnicalDocsModal from './AddTechnicalDocsModal';
import {
  addTechnicalDocs,
  deleteTechnicalDocs,
  getTechnicalDocs,
  updateTechnicalDocs,
} from '../../redux/technicalDoc/actionCreator';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';

const TechnicalDocs = () => {
  const dispatch = useDispatch();
  const [queryState, setQueryState] = useState({ visible: false, currentPage: 1, pageLimit: 50 });
  const { technicalDocs, currentPage, loading, total } = useSelector(state => {
    return {
      technicalDocs: state.technicalDocs.technicalDocs,
      loading: state.technicalDocs.loading,
      total: state.technicalDocs.total,
      currentPage: state.technicalDocs.currentPage,
    };
  });

  const showModal = () => {
    setQueryState({ ...queryState, visible: true });
  };

  const onCancel = () => {
    setQueryState({ ...queryState, visible: false });
  };

  const handleAdd = async (data, resetForm) => {
    const formValues = new FormData();
    if (data['pdf-file']) data['pdf-file'].map(file => formValues.append('pdf-file', file.originFileObj));
    formValues.set('file_name', data.file_name);
    const res = await dispatch(addTechnicalDocs(formValues));
    if (res?.status === 201) {
      setQueryState({ ...queryState, visible: false });
      resetForm();
      dispatch(getTechnicalDocs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };
  const handleEdit = async (id, data) => {
    const formValues = new FormData();
    if (data['pdf-file']) data['pdf-file'].map(file => formValues.append('pdf-file', file.originFileObj));
    formValues.set('file_name', data.file_name);
    const res = await dispatch(updateTechnicalDocs(id, formValues));
    if (res?.status === 200) {
      setQueryState({ ...queryState, visible: false });
      dispatch(getTechnicalDocs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };
  const handleDelete = async id => {
    const res = await dispatch(deleteTechnicalDocs(id));
    if (res?.status === 200) {
      setQueryState({ ...queryState, visible: false });
      dispatch(getTechnicalDocs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };

  useEffect(() => {
    dispatch(getTechnicalDocs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  }, [queryState.currentPage, queryState.pageLimit]);

  return (
    <>
      <PageHeader
        ghost
        title="Technical Documentation"
        subTitle={
          technicalDocs.length > 0 && (
            <span className="title-counter">
              {technicalDocs.length} {Pluralize('Technical Documentation', technicalDocs.length)}{' '}
            </span>
          )
        }
        buttons={[
          <Button onClick={showModal} key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
            <FeatherIcon icon="plus" size={16} /> Add New
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={[14]}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <TechnicalDocsTable
                total={total}
                currentPage={currentPage}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                pageLimit={queryState.pageLimit}
                setParentState={setQueryState}
                technicalDocs={technicalDocs}
              />
            )}
          </Col>
        </Row>
      </Main>
      <AddTechnicalDocsModal visible={queryState.visible} handleSubmit={handleAdd} onCancel={onCancel} />
    </>
  );
};

export default TechnicalDocs;
