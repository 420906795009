/* eslint-disable no-underscore-dangle */
import { Col, Row, Switch } from 'antd';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { blogInitialFormData, blogValidationSchema } from './blog.schema';
import { Button } from '../../../components/buttons/buttons';
import FormInput from '../../../components/input/input';
import FormSelect from '../../../components/select/FormSelect';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import { BasicFormWrapper } from '../../styled';
import FormUpload from '../../../components/upload/FormUpload';
import { addNewBlog, editBlog } from '../../../redux/blog/actionCreator';
import { getAllBlogCategories } from '../../../redux/blogcategory/actionCreator';
import { getAllUsers } from '../../../redux/users/actionCreator';
import DivBreak from '../../../components/divBreak/DivBreak';

const BlogForm = props => {
  const { formData, setFormData } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { categories, users, blogLoading } = useSelector(state => {
    return {
      categories: state.blogCategory.categories,
      users: state.user.users,
      blogLoading: state.blog.loading,
    };
  });

  const [isMultiLangFlag, setIsMultiLangFlag] = useState(false);

  useEffect(() => {
    setIsMultiLangFlag(formData?.isMultiLang.includes('true'));
  }, [formData]);

  function getCategoryDropdownOpt() {
    const dropdownOpt = categories?.map(category => ({
      value: category?._id ?? '',
      key: category?._id ?? '',
      label: `${' '}${category?.details[0]?.category}`,
    }));
    return dropdownOpt ?? [];
  }

  function getAuthorDropdownOpt() {
    const dropdownOpt = users?.map(user => ({
      value: user?._id ?? '',
      key: user?._id ?? '',
      label: `${' '}${user?.name}`,
    }));
    return dropdownOpt ?? [];
  }

  function getIsFeaturedDropdownOpt() {
    return [
      {
        value: 'true',
        key: `yes`,
        label: `${' '}Yes`,
      },
      {
        value: 'false',
        key: `no`,
        label: `${' '}No`,
      },
    ];
  }

  async function handleFormSubmit(requestData, resetForm) {
    try {
      const apiReqData = {
        featured: requestData.featured,
        category: requestData.category,
        author: requestData.author,
      };

      const transformedTitleAndDesc = [];
      LANGUAGE_BY_DOMAIN.forEach(langItem => {
        if (requestData.isMultiLang === 'true' || langItem[1] === DEFAULT_LANG.value) {
          transformedTitleAndDesc.push({
            lang: langItem[1],
            title: requestData[`title${langItem[1]}`],
            description: requestData[`description${langItem[1]}`],
          });
        }
      });

      const formValue = new FormData();
      Object.keys(apiReqData).forEach(key => {
        if (apiReqData[key]) {
          formValue.append(key, apiReqData[key]);
        }
      });

      formValue.append('details', JSON.stringify(transformedTitleAndDesc));

      if (requestData?.image && requestData?.image[0]?.originFileObj)
        formValue.append('image', requestData?.image[0]?.originFileObj);

      // eslint-disable-next-line no-underscore-dangle
      const res = await dispatch(
        requestData?._id ? editBlog(requestData?._id, formValue, history) : addNewBlog(formValue, history),
      );
      if (res?.data?.status) {
        resetForm();
        setFormData({ ...blogInitialFormData });
        dispatch(getAllUsers('?role=writer'));
        getAllBlogCategories(dispatch);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Formik
      initialValues={formData}
      validationSchema={blogValidationSchema(isMultiLangFlag)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        // eslint-disable-next-line no-use-before-define
        handleFormSubmit(values, resetForm);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form>
            <BasicFormWrapper>
              <div className="add-product-block">
                <Row gutter={[16, 16]}>
                  {/* language switcher */}
                  <Col xs={24}>
                    <h4>Translate</h4>
                    <Switch
                      checked={values.isMultiLang === 'true'}
                      onChange={checked => {
                        setFieldValue('isMultiLang', String(checked));
                        setIsMultiLangFlag(checked);
                      }}
                    />
                  </Col>

                  {/* title default  */}
                  <Col xs={24}>
                    <FormInput
                      name={`title${DEFAULT_LANG.value}`}
                      label={`Title (${DEFAULT_LANG.label})`}
                      value={values[`title${DEFAULT_LANG.value}`]}
                      handleChange={handleChange}
                      placeholder="Title"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* title dynamic  */}
                  {values.isMultiLang === 'true' &&
                    LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                      return (
                        <Col xs={24} key={index}>
                          <FormInput
                            name={`title${value}`}
                            label={`Title (${label})`}
                            value={values[`title${value}`]}
                            handleChange={handleChange}
                            placeholder="Title"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      );
                    })}

                  {/* description default  */}
                  <Col xs={24}>
                    <FormInput
                      type="editor"
                      name={`description${DEFAULT_LANG.value}`}
                      label={`Description (${DEFAULT_LANG.label})`}
                      editorState={values[`description${DEFAULT_LANG.value}`] || ''}
                      handleEditorStateChange={content => {
                        setFieldValue(`description${DEFAULT_LANG.value}`, content);
                      }}
                      placeholder="Description"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* description dynamic  */}
                  {values.isMultiLang === 'true' &&
                    LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => (
                      <Col xs={24} key={index}>
                        <FormInput
                          type="editor"
                          name={`description${value}`}
                          label={`Description (${label})`}
                          editorState={values[`description${value}`] || ''}
                          handleEditorStateChange={content => {
                            setFieldValue(`description${value}`, content);
                          }}
                          placeholder="Description"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    ))}

                  {/* is featured */}
                  <Col xs={24} sm={12} md={8}>
                    <FormSelect
                      name="featured"
                      label="Is Blog Featured?"
                      value={values.featured}
                      options={getIsFeaturedDropdownOpt() ?? []}
                      handleChange={value => {
                        setFieldValue('featured', value);
                      }}
                      placeholder="Select"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* category */}
                  <Col xs={24} sm={12} md={8}>
                    <FormSelect
                      name="category"
                      label="Blog Category"
                      value={values.category}
                      options={getCategoryDropdownOpt() ?? []}
                      handleChange={value => {
                        setFieldValue('category', value);
                      }}
                      placeholder="Select"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* author */}
                  <Col xs={24} sm={12} md={8}>
                    <FormSelect
                      name="author"
                      label="Blog Author"
                      value={values.author}
                      options={getAuthorDropdownOpt() ?? []}
                      handleChange={value => {
                        setFieldValue('author', value);
                      }}
                      placeholder="Select"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* image */}
                  <Col xs={24}>
                    <FormUpload
                      name="image"
                      label="Blog Image"
                      value={values.image}
                      handleChange={info => {
                        const fileList = [...info.fileList].slice(-1);
                        setFieldValue('image', fileList.length > 0 ? [fileList[0]] : null);
                      }}
                      accept="image/*"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              </div>

              <DivBreak />

              <Row justify="end" align="middle">
                <Col>
                  <Button
                    style={{
                      backgroundColor: '#155BDC',
                      color: '#fff',
                      marginRight: 5,
                      height: '40px',
                      fontWeight: '500',
                    }}
                    htmlType="submit"
                    loading={blogLoading}
                  >
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {values?._id ? 'Update Blog' : 'Add Blog'}
                  </Button>
                </Col>
              </Row>
            </BasicFormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

BlogForm.propTypes = {
  formData: propTypes.object.isRequired,
  setFormData: propTypes.func.isRequired,
};

export default BlogForm;
