import { message } from 'antd';
//
import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getFuelPricesBegin,
  getFuelPricesFail,
  getFuelPricesSuccess,
  addFuelPriceBegin,
  addFuelPriceFailed,
  addFuelPriceSuccess,
  updateFuelPriceBegin,
  updateFuelPriceFailed,
  updateFuelPriceSuccess,
  deleteFuelPriceBegin,
  deleteFuelPriceFailed,
  deleteFuelPriceSuccess,
} = actions;

let cancelToken;
export const getFuelPrices = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getFuelPricesSuccess({ fuelPrices: [] }));
    dispatch(getFuelPricesBegin());
    const res = await axios.get(`fuel-price${filter ? filter : ''}`, {
      cancelToken: cancelToken?.token,
      headers: { ...getHeaders() },
    });
    dispatch(
      getFuelPricesSuccess({
        fuelPrices: res.data?.fuelPrices,
        total: res.data.total,
        currentPage: res.data.currentPage,
        pageLimit: res.data.pageLimit,
      }),
    );
    return res.data;
  } catch (err) {
    dispatch(getFuelPricesFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

export const addFuelPrice = formData => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addFuelPriceBegin());
    const res = await axios.post('fuel-price', formData, {
      headers: { ...getHeaders() },
    });
    const newFuelPrices = [res.data, ...state.fuelPrice.fuelPrices];
    dispatch(addFuelPriceSuccess(newFuelPrices));
    message.success('Fuel Price added successfully');
    return res;
  } catch (err) {
    dispatch(addFuelPriceFailed(err?.response.data?.msg));
    dispatch(errorHandling(err));
  }
};

export const editFuelPrice = (id, data) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateFuelPriceBegin());
    const res = await axios.put(`fuel-price/${id}`, data, {
      headers: { ...getHeaders() },
    });
    const updatedFuel = state.fuelPrice.fuelPrices.map(fuel => {
      if (fuel._id === id) return res.data;
      return fuel;
    });
    dispatch(updateFuelPriceSuccess(updatedFuel));
    message.success('Fuel Price updated successfully');
  } catch (err) {
    dispatch(updateFuelPriceFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

export const deleteFuelType = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteFuelPriceBegin());
    await axios.delete(`fuel-price/${id}`, { headers: { ...getHeaders() } });
    dispatch(deleteFuelPriceSuccess(state.fuelPrice.fuelPrices.filter(fuel => fuel._id !== id)));
    message.success('Fuel Price deleted successfully');
  } catch (err) {
    dispatch(deleteFuelPriceFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};
