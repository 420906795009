import React, { useState, useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
//
import ErrorLogTable from './ErrorLogTable';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { deleteErrorLog, deleteMultipleErrorLogs, getAllErrorLogs } from '../../redux/errorLog/actionCreator';
import { BottomAction } from '../car/Style';
import { Button } from '../../components/buttons/buttons';
import { formatNumber } from '../../utility/utility';

const ErrorLog = () => {
  const dispatch = useDispatch();
  const [queryState, setQueryState] = useState({ visible: false, currentPage: 1, pageLimit: 50 });
  const [selectedErrorLogs, setSelectedErrorLogs] = useState([]);
  const { errorLogs, loading, currentPage, pageLimit, total } = useSelector(state => {
    return {
      loading: state.errorLogs.loading,
      errorLogs: state.errorLogs.errorLogs,
      currentPage: state.errorLogs.currentPage,
      pageLimit: state.errorLogs.pageLimit,
      total: state.errorLogs.total,
    };
  });

  const handleDelete = async id => {
    const res = await dispatch(deleteErrorLog(id));
    if (res?.status === 200) {
      setQueryState({ ...queryState, visible: false });
      dispatch(getAllErrorLogs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };

  const handleMultipleDelete = async () => {
    const res = await dispatch(deleteMultipleErrorLogs(selectedErrorLogs));
    if (res.status === 200) {
      setSelectedErrorLogs([]);
      dispatch(getAllErrorLogs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };

  useEffect(() => {
    dispatch(getAllErrorLogs(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  }, [queryState.currentPage, queryState.pageLimit]);

  return (
    <>
      <PageHeader
        ghost
        title="Error Logs"
        subTitle={
          errorLogs.length > 0 && (
            <span className="title-counter">
              {formatNumber(total)} {pluralize('Error Log', total)}
            </span>
          )
        }
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <ErrorLogTable
                currentPage={currentPage}
                pageLimit={pageLimit}
                errorLogs={errorLogs}
                handleDelete={handleDelete}
                setParentState={setQueryState}
                total={total}
                selectedErrorLogs={selectedErrorLogs}
                setSelectedErrorLogs={setSelectedErrorLogs}
              />
            )}
          </Col>
        </Row>
      </Main>
      {selectedErrorLogs.length > 0 && (
        <BottomAction>
          <div className="bottom-action-container">
            <span>{selectedErrorLogs.length} Logs Selected</span>
            <Button
              className="action-btn"
              style={{ backgroundColor: '#155BDC', color: '#fff' }}
              onClick={handleMultipleDelete}
            >
              Delete Error Logs
            </Button>
          </div>
        </BottomAction>
      )}
    </>
  );
};

export default ErrorLog;
