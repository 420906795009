import actions from './actions';

const {
  GET_PROMO_CAR_BEGIN,
  GET_PROMO_CAR_FAIL,
  GET_PROMO_CAR_SUCCESS,
  REMOVE_PROMO_CAR_BEGIN,
  REMOVE_PROMO_CAR_FAIL,
  REMOVE_PROMO_CAR_SUCCESS,
  REORDER_PROMO_CAR_BEGIN,
  REORDER_PROMO_CAR_FAIL,
  REORDER_PROMO_CAR_SUCCESS,
} = actions;

const initState = {
  promoCars: [],
  loading: false,
  error: null,
  reorder_loading: false,
};

const PromoCarReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PROMO_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PROMO_CAR_SUCCESS:
      return {
        ...state,
        promoCars: data,
        loading: false,
      };
    case GET_PROMO_CAR_FAIL:
      return {
        ...state,
        err,
      };
    case REMOVE_PROMO_CAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PROMO_CAR_SUCCESS:
      return {
        ...state,
        promoCars: data,
        loading: false,
      };
    case REMOVE_PROMO_CAR_FAIL:
      return {
        ...state,
        err,
      };
    case REORDER_PROMO_CAR_BEGIN:
      return {
        ...state,
        reorder_loading: true,
      };
    case REORDER_PROMO_CAR_SUCCESS:
      return {
        ...state,
        promoCars: data,
        reorder_loading: false,
      };
    case REORDER_PROMO_CAR_FAIL:
      return {
        ...state,
        err,
      };
    default:
      return {
        ...state,
      };
  }
};

export default PromoCarReducer;
