const actions = {
  GET_ELECTRICITY_PRICES_BEGIN: 'GET_ELECTRICITY_PRICES_BEGIN',
  GET_ELECTRICITY_PRICES_SUCCESS: 'GET_ELECTRICITY_PRICES_SUCCESS',
  GET_ELECTRICITY_PRICES_FAILED: 'GET_ELECTRICITY_PRICES_FAILED',

  ADD_ELECTRICITY_PRICE_BEGIN: 'ADD_ELECTRICITY_PRICE_BEGIN',
  ADD_ELECTRICITY_PRICE_SUCCESS: 'ADD_ELECTRICITY_PRICE_SUCCESS',
  ADD_ELECTRICITY_PRICE_FAILED: 'ADD_ELECTRICITY_PRICE_FAILED',

  UPDATE_ELECTRICITY_PRICE_BEGIN: 'UPDATE_ELECTRICITY_PRICE_BEGIN',
  UPDATE_ELECTRICITY_PRICE_SUCCESS: 'UPDATE_ELECTRICITY_PRICE_SUCCESS',
  UPDATE_ELECTRICITY_PRICE_FAILED: 'UPDATE_ELECTRICITY_PRICE_FAILED',

  DELETE_ELECTRICITY_PRICE_BEGIN: 'DELETE_ELECTRICITY_PRICE_BEGIN',
  DELETE_ELECTRICITY_PRICE_SUCCESS: 'DELETE_ELECTRICITY_PRICE_SUCCESS',
  DELETE_ELECTRICITY_PRICE_FAILED: 'DELETE_ELECTRICITY_PRICE_FAILED',

  getElectricityPricesBegin: () => {
    return {
      type: actions.GET_ELECTRICITY_PRICES_BEGIN,
    };
  },

  getElectricityPricesSuccess: data => {
    return {
      type: actions.GET_ELECTRICITY_PRICES_SUCCESS,
      data,
    };
  },
  getElectricityPricesFail: err => {
    return {
      type: actions.GET_ELECTRICITY_PRICES_FAILED,
      err,
    };
  },
  addElectricityPriceBegin: () => {
    return {
      type: actions.ADD_ELECTRICITY_PRICE_BEGIN,
    };
  },
  addElectricityPriceSuccess: data => {
    return {
      type: actions.ADD_ELECTRICITY_PRICE_SUCCESS,
      data,
    };
  },
  addElectricityPriceFailed: err => {
    return {
      type: actions.ADD_ELECTRICITY_PRICE_FAILED,
      err,
    };
  },
  updateElectricityPriceBegin: () => {
    return {
      type: actions.UPDATE_ELECTRICITY_PRICE_BEGIN,
    };
  },
  updateElectricityPriceSuccess: data => {
    return {
      type: actions.UPDATE_ELECTRICITY_PRICE_SUCCESS,
      data,
    };
  },
  updateElectricityPriceFailed: err => {
    return {
      type: actions.UPDATE_ELECTRICITY_PRICE_FAILED,
      err,
    };
  },
  deleteElectricityPriceBegin: () => {
    return {
      type: actions.DELETE_ELECTRICITY_PRICE_BEGIN,
    };
  },
  deleteElectricityPriceSuccess: data => {
    return {
      type: actions.DELETE_ELECTRICITY_PRICE_SUCCESS,
      data,
    };
  },
  deleteElectricityPriceFailed: err => {
    return {
      type: actions.DELETE_ELECTRICITY_PRICE_FAILED,
      err,
    };
  },
};

export default actions;
