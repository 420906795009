import React, { Fragment, useState } from 'react';
import { Col, Divider, Pagination, Row, Empty } from 'antd';
import FeatherIcon from 'feather-icons-react';
//
import EditTopCarSale from './EditTopCarSale';
import DeleteTopCarSale from './DeleteTopCarSale';
import { Cards } from '../../components/cards/frame/cards-frame';
import { UserTableStyleWrapper } from '../pages/style';
import { TableWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';

const TopCarSalesTable = ({ topCarSales, handleDelete, handleEdit, currentPage, pageLimit, total, setParentState }) => {
  const [state, setState] = useState({
    editModalVisible: false,
    deleteModalVisible: false,
  });
  const [topCarSale, setTopCarSale] = useState(null);

  const handlePageOrLimitChange = data => {
    setParentState(prev => ({ ...prev, currentPage: data }));
  };

  const showModal = (topCarSaleId, modalType) => {
    const selectedTopCarSale = topCarSales.find(el => el._id === topCarSaleId);
    if (!selectedTopCarSale) return;
    if (modalType === 'edit') setState(prev => ({ ...prev, editModalVisible: !prev.editModalVisible }));
    else if (modalType === 'delete') setState(prev => ({ ...prev, deleteModalVisible: !prev.deleteModalVisible }));
    setTopCarSale(selectedTopCarSale);
  };

  const handleCancel = () => {
    setTopCarSale(null);
    setState({ deleteModalVisible: false, editModalVisible: false });
  };

  const handleEditSubmit = (id, formData) => {
    handleEdit(id, formData);
    setTopCarSale(null);
    setState({ deleteModalVisible: false, editModalVisible: false });
  };

  const handleDeleteSubmit = id => {
    handleDelete(id);
    setTopCarSale(null);
    setState({ deleteModalVisible: false, editModalVisible: false });
  };

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Row
            style={{
              borderRadius: '10px',
              padding: '16px',
              color: 'rgb(90, 95, 125)',
              fontWeight: '500',
              backgroundColor: 'rgba(0,0,0,.06)',
            }}
          >
            <Col xs={2}>SN</Col>
            <Col xs={6}>Brand</Col>
            <Col xs={6}>Model</Col>
            <Col xs={6} style={{ textAlign: 'center' }}>
              Units Sold
            </Col>
            <Col xs={4} style={{ textAlign: 'center' }}>
              Actions
            </Col>
          </Row>
          {topCarSales && topCarSales.length > 0 ? (
            topCarSales.map(carSale => (
              <Row key={carSale._id} style={{ margin: '20px 9px' }}>
                <Col xs={24}>
                  <Row>
                    <Col xs={20}>
                      <h3 style={{ fontSize: '14px' }}>
                        Year: <span style={{ fontWeight: 700 }}>{carSale.year}</span>
                      </h3>
                    </Col>
                    <Col xs={4}>
                      <div
                        className="table-actions fuel-price-actions"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Button
                          style={{ padding: '0 11px' }}
                          className="btn-icon info"
                          shape="circle"
                          onClick={() => showModal(carSale._id, 'edit')}
                        >
                          <FeatherIcon icon="edit" size={16} />
                        </Button>
                        <Button
                          style={{ padding: '0 11px' }}
                          className="btn-icon danger"
                          to="#"
                          shape="circle"
                          onClick={() => showModal(carSale._id, 'delete')}
                        >
                          <FeatherIcon icon="trash-2" size={16} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Divider style={{ margin: '12px 0' }} />
                {carSale.car_sales.map((sale, saleIndex) => (
                  <Fragment key={sale._id}>
                    <Col xs={24}>
                      <Row style={{ padding: '0 16px' }}>
                        <Col xs={2}>{saleIndex + 1}</Col>
                        <Col xs={6}>{sale.brand?.brand}</Col>
                        <Col xs={6}>{sale.model?.model}</Col>
                        <Col xs={6} style={{ textAlign: 'center' }}>
                          {sale.sold ? new Intl.NumberFormat('fr-FR').format(sale.sold) : '0'}
                        </Col>
                      </Row>
                    </Col>
                    <Divider style={{ margin: '12px 0' }} />
                  </Fragment>
                ))}
              </Row>
            ))
          ) : (
            <Empty style={{ padding: '10px 0' }} description="No Car Sales Data Available" />
          )}
          <Row align="end">
            <Pagination
              defaultPageSize={pageLimit}
              total={total}
              current={currentPage}
              showTotal={(totalPage, range) => `${range[0]}-${range[1]} of ${totalPage}`}
              onChange={handlePageOrLimitChange}
            />
          </Row>
        </TableWrapper>
      </UserTableStyleWrapper>
      {topCarSales && Object.keys(topCarSales).length > 0 && (
        <>
          {state.editModalVisible && topCarSale && (
            <EditTopCarSale
              visible={state.editModalVisible}
              onCancel={handleCancel}
              handleEdit={handleEditSubmit}
              carSaleId={topCarSale._id}
              carSale={{
                year: topCarSale.year,
                car_sales: topCarSale.car_sales.map(el => ({
                  sold: el.sold,
                  brand: el.brand?._id,
                  model: el.model?._id,
                })),
              }}
            />
          )}
          {state.deleteModalVisible && topCarSale && (
            <DeleteTopCarSale
              visible={state.deleteModalVisible}
              onCancel={handleCancel}
              handleDelete={handleDeleteSubmit}
              carSaleId={topCarSale._id}
              carSale={{
                ...topCarSale,
                car_sales: topCarSale.car_sales.map(el => ({
                  sold: el.sold,
                  brand: el.brand?._id,
                  model: el.model?._id,
                })),
              }}
            />
          )}
        </>
      )}
    </Cards>
  );
};

export default TopCarSalesTable;
