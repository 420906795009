import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
//
import FuelPriceTable from './FuelPriceTable';
import CreateFuelModal from './CreateFuelPriceModal';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import { addFuelPrice, deleteFuelType, editFuelPrice, getFuelPrices } from '../../redux/fuelPrice/actionCreator';

const FuelPrice = () => {
  const dispatch = useDispatch();

  const [queryState, setQueryState] = useState({ visible: false, currentPage: 1, pageLimit: 10 });

  // show add Price Modal
  const showModal = () => setQueryState(prev => ({ ...prev, visible: true }));

  // hide add Price Modal
  const onCancel = () => setQueryState(prev => ({ ...prev, visible: false }));

  const handleAdd = async (formData, resetForm) => {
    const fuelPrices = formData.fuelPrices.map(el => ({
      ...el,
      coastalPrice: el.coastalPrice || undefined, // api expects the value to be undefined if the value doesn't exist
    }));
    const res = await dispatch(addFuelPrice({ ...formData, fuelPrices }));
    if (res?.data?.status) {
      resetForm();
      setQueryState(prev => ({ ...prev, visible: false }));
      dispatch(getFuelPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };

  const handleEdit = (id, data) => {
    const fuelPrices = data.fuelPrices.map(el => ({
      ...el,
      coastalPrice: el.coastalPrice || undefined, // api expects the value to be undefined if the value doesn't exist
    }));
    dispatch(editFuelPrice(id, { ...data, fuelPrices }));
    dispatch(getFuelPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  };

  const handleDelete = id => {
    dispatch(deleteFuelType(id));
    dispatch(getFuelPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  };

  const { currentPage, fuelPrices, loading, pageLimit, totalPrices } = useSelector(state => {
    return {
      fuelPrices: state.fuelPrice.fuelPrices,
      currentPage: state.fuelPrice.currentPage,
      pageLimit: state.fuelPrice.pageLimit,
      totalPrices: state.fuelPrice.total,
      loading: state.fuelPrice.loading,
    };
  });

  useEffect(() => {
    dispatch(getFuelPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  }, [queryState.currentPage, queryState.pageLimit]);

  return (
    <>
      <PageHeader
        ghost
        title="Fuel Price Tracker"
        subTitle={totalPrices > 0 && <span className="title-counter">{totalPrices} Fuel Prices </span>}
        buttons={[
          <Button onClick={showModal} key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
            <FeatherIcon icon="plus" size={16} />
            Add New
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <FuelPriceTable
                prices={fuelPrices}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalPrices={totalPrices}
                setParentState={setQueryState}
              />
            )}
          </Col>
        </Row>
        <CreateFuelModal visible={queryState.visible} onCancel={onCancel} handleSubmit={handleAdd} />
      </Main>
    </>
  );
};

export default FuelPrice;
