import * as Yup from 'yup';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicBlogFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`title${item[1]}`]: '',
      [`description${item[1]}`]: '',
    };
  });
  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicBlogFields();
  const initialFormData = {
    isMultiLang: 'false',
    featured: null,
    category: null,
    author: null,
    image: null,
    ...dynamicFields,
  };

  return initialFormData;
}

export const blogInitialFormData = getInitialFormData();

export function blogValidationSchema(isMultiLangFlag) {
  let validationFields = {
    [`title${DEFAULT_LANG.value}`]: Yup.mixed().required(`Title(${DEFAULT_LANG.label}) is required!`),
    [`description${DEFAULT_LANG.value}`]: Yup.mixed()
      .test('description', '', function(fieldValue) {
        if (!fieldValue || fieldValue === '<p><br></p>' || fieldValue === '<p></p>' || fieldValue === '') {
          return this.createError({
            message: `Description(${DEFAULT_LANG.label}) is required!`,
          });
        }
        return true;
      })
      .required(`Description(${DEFAULT_LANG.label}) is required!`),
  };

  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
    if (isMultiLangFlag) {
      validationFields = {
        ...validationFields,
        [`title${value}`]: Yup.string().required(`Title(${label}) is required!`),
        [`description${value}`]: Yup.mixed()
          .test('description', '', fieldValue => {
            if (!fieldValue || fieldValue === '<p><br></p>' || fieldValue === '<p></p>' || fieldValue === '') {
              return this.createError({
                message: `Description(${label}) is required!`,
              });
            }
            return true;
          })
          .required(`Description(${label}) is required!`),
      };
    } else {
      validationFields = {
        ...validationFields,
        [`title${value}`]: Yup.string(),
        [`description${value}`]: Yup.mixed(),
      };
    }
  });

  return Yup.object().shape({
    isMultiLang: Yup.string(),
    featured: Yup.string()
      .nullable()
      .required('Please select is blog featured!'),
    category: Yup.string()
      .nullable()
      .required('Please select category!'),
    author: Yup.string()
      .nullable()
      .required('Please select author!'),
    image: Yup.mixed().required('Image is required!'),
    ...validationFields,
  });
}

export const blogTableColumns = [
  {
    title: 'S.No',
    dataIndex: 'sNo',
    key: 'sNo',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Featured',
    dataIndex: 'featured',
    key: 'featured',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
