import React, { Fragment, useState } from 'react';
import { Col, Divider, Pagination, Row } from 'antd';
import FeatherIcon from 'feather-icons-react';
//
import EditFuelPriceModal from './EditElectricityPriceModal';
import DeleteFuelPriceModal from './DeleteElectricityPriceModal';
import { Cards } from '../../components/cards/frame/cards-frame';
import { UserTableStyleWrapper } from '../pages/style';
import { TableWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { formatDate } from '../../utility/utility';

const ElectricityPriceTable = ({
  prices,
  handleDelete,
  handleEdit,
  currentPage,
  pageLimit,
  totalPrices,
  setParentState,
}) => {
  const [state, setState] = useState({
    editModalVisible: false,
    deleteModalVisible: false,
  });
  const [electricityPrice, setElectricityPrice] = useState(null);

  const handlePageOrLimitChange = data => {
    setParentState(prev => ({ ...prev, currentPage: data }));
  };

  const showModal = (price_id, modalType) => {
    const selectedElectricityPrice = prices.find(el => el._id === price_id);
    if (!selectedElectricityPrice) return;
    if (modalType === 'edit') setState(prev => ({ ...prev, editModalVisible: !prev.editModalVisible }));
    else if (modalType === 'delete') setState(prev => ({ ...prev, deleteModalVisible: !prev.deleteModalVisible }));
    setElectricityPrice(selectedElectricityPrice);
  };

  const handleCancel = () => {
    setElectricityPrice(null);
    setState({ deleteModalVisible: false, editModalVisible: false });
  };

  const handleDeleteConfirmation = electricityId => {
    handleDelete(electricityId);
    setElectricityPrice(null);
    setState({ deleteModalVisible: false, editModalVisible: false });
  };

  const handleEditSubmit = (id, formData) => {
    handleEdit(id, formData);
    setElectricityPrice(null);
    setState({ deleteModalVisible: false, editModalVisible: false });
  };

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Row
            style={{
              borderRadius: '10px',
              padding: '16px',
              color: 'rgb(90, 95, 125)',
              fontWeight: '500',
              backgroundColor: 'rgba(0,0,0,.06)',
            }}
          >
            <Col xs={2}>SN</Col>
            <Col xs={6}>Timing / Tariff</Col>
            <Col xs={12}>Electricity Price (Per kWh)</Col>
            <Col xs={4} style={{ textAlign: 'center' }}>
              Actions
            </Col>
          </Row>
          {prices &&
            prices.map(price => (
              <Row key={price._id} style={{ margin: '20px 9px', padding: '0 16px' }}>
                <Col xs={24}>
                  <Row>
                    <Col xs={20}>
                      <h3 style={{ fontSize: '14px' }}>
                        {' '}
                        Effective Date: <span style={{ fontWeight: 700 }}>{formatDate(price.effectiveDate)}</span>{' '}
                      </h3>
                    </Col>
                    <Col xs={4}>
                      <div
                        className="table-actions fuel-price-actions"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Button
                          style={{ padding: '0 11px' }}
                          className="btn-icon info"
                          shape="circle"
                          onClick={() => showModal(price._id, 'edit')}
                        >
                          <FeatherIcon icon="edit" size={16} />
                        </Button>
                        <Button
                          style={{ padding: '0 11px' }}
                          className="btn-icon danger"
                          to="#"
                          shape="circle"
                          onClick={() => showModal(price._id, 'delete')}
                        >
                          <FeatherIcon icon="trash-2" size={16} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Divider style={{ margin: '12px 0' }} />
                {price.timeBasedPrices.map((item, electricityPriceIndex) => (
                  <Fragment key={item._id}>
                    <Col xs={24}>
                      <Row>
                        <Col xs={2}>{electricityPriceIndex + 1}</Col>
                        <Col xs={6}>{item.timing}</Col>
                        <Col xs={6}>
                          {new Intl.NumberFormat('fr-FR').format(item.price)}{' '}
                          {process.env.REACT_APP_DEFAULT_CURRENCY}
                        </Col>
                      </Row>
                    </Col>
                    <Divider style={{ margin: '12px 0' }} />
                  </Fragment>
                ))}
              </Row>
            ))}
          <Row align="end" style={{ padding: '0 16px' }}>
            <Pagination
              defaultPageSize={pageLimit}
              total={totalPrices}
              current={currentPage}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              onChange={handlePageOrLimitChange}
            />
          </Row>
        </TableWrapper>
      </UserTableStyleWrapper>
      {Object.keys(prices).length > 0 && (
        <>
          {state.editModalVisible && electricityPrice && (
            <EditFuelPriceModal
              visible={state.editModalVisible}
              onCancel={handleCancel}
              handleEdit={handleEditSubmit}
              electricityPriceId={electricityPrice._id}
              priceValue={{
                effectiveDate: electricityPrice.effectiveDate,
                timeBasedPrices: electricityPrice.timeBasedPrices.map(el => {
                  return {
                    timing: el.timing,
                    price: el.price,
                  };
                }),
              }}
            />
          )}
          {state.deleteModalVisible && electricityPrice && (
            <DeleteFuelPriceModal
              electricityPrice={electricityPrice}
              handleDelete={handleDeleteConfirmation}
              onCancel={handleCancel}
              visible={state.deleteModalVisible}
            />
          )}
        </>
      )}
    </Cards>
  );
};

export default ElectricityPriceTable;
