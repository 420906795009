import actions from './actions';

const {
  GET_FUEL_PRICES_BEGIN,
  GET_FUEL_PRICES_FAILED,
  GET_FUEL_PRICES_SUCCESS,
  ADD_FUEL_PRICE_BEGIN,
  ADD_FUEL_PRICE_FAILED,
  ADD_FUEL_PRICE_SUCCESS,
  DELETE_FUEL_PRICE_BEGIN,
  DELETE_FUEL_PRICE_FAILED,
  DELETE_FUEL_PRICE_SUCCESS,
  UPDATE_FUEL_PRICE_BEGIN,
  UPDATE_FUEL_PRICE_FAILED,
  UPDATE_FUEL_PRICE_SUCCESS,
} = actions;

const initState = {
  fuelPrices: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const FuelPriceReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_FUEL_PRICES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_FUEL_PRICES_SUCCESS:
      return {
        ...state,
        fuelPrices: data.fuelPrices,
        total: data.total,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_FUEL_PRICES_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_FUEL_PRICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FUEL_PRICE_SUCCESS:
      return {
        ...state,
        fuelPrices: data,
        loading: false,
      };
    case ADD_FUEL_PRICE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_FUEL_PRICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FUEL_PRICE_SUCCESS:
      return {
        ...state,
        fuelPrices: data,
        loading: false,
      };
    case UPDATE_FUEL_PRICE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_FUEL_PRICE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FUEL_PRICE_SUCCESS:
      return {
        ...state,
        fuelPrices: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_FUEL_PRICE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default FuelPriceReducer;
