const actions = {
  GET_TOOLS_PAGES_CONTENT_BEGIN: 'GET_TOOLS_PAGE_CONTENT',
  GET_TOOLS_PAGES_CONTENT_SUCCESS: 'GET_TOOLS_PAGE_CONTENT_SUCCESS',
  GET_TOOLS_PAGES_CONTENT_FAILED: 'GET_TOOLS_PAGE_CONTENT_FAILED',

  GET_SINGLE_TOOLS_PAGE_CONTENT_BEGIN: 'GET_SINGLE_TOOLS_PAGE_CONTENT_BEGIN',
  GET_SINGLE_TOOLS_PAGE_CONTENT_SUCCESS: 'GET_SINGLE_TOOLS_PAGE_CONTENT_SUCCESS',
  GET_SINGLE_TOOLS_PAGE_CONTENT_FAILED: 'GET_SINGLE_TOOLS_PAGE_CONTENT_FAILED',

  UPDATE_TOOLS_PAGE_CONTENT_BEGIN: 'UPDATE_TOOLS_PAGE_CONTENT_BEGIN',
  UPDATE_TOOLS_PAGE_CONTENT_SUCCESS: 'UPDATE_TOOLS_PAGE_CONTENT_SUCCESS',
  UPDATE_TOOLS_PAGE_CONTENT_FAILED: 'UPDATE_TOOLS_PAGE_CONTENT_FAILED',

  getAllToolsPagesBegin: () => {
    return {
      type: actions.GET_TOOLS_PAGES_CONTENT_BEGIN,
    };
  },

  getAllToolsPagesSuccess: data => {
    return {
      type: actions.GET_TOOLS_PAGES_CONTENT_SUCCESS,
      data,
    };
  },

  getAllToolsPagesFailed: err => {
    return {
      type: actions.GET_SINGLE_TOOLS_PAGE_CONTENT_FAILED,
      err,
    };
  },

  getToolsPageBegin: () => {
    return {
      type: actions.GET_SINGLE_TOOLS_PAGE_CONTENT_BEGIN,
    };
  },

  getToolsPageSuccess: data => {
    return {
      type: actions.GET_SINGLE_TOOLS_PAGE_CONTENT_SUCCESS,
      data,
    };
  },

  getToolsPageFailed: err => {
    return {
      type: actions.GET_SINGLE_TOOLS_PAGE_CONTENT_FAILED,
      err,
    };
  },

  updateToolsPageBegin: () => {
    return {
      type: actions.UPDATE_TOOLS_PAGE_CONTENT_BEGIN,
    };
  },

  updateToolsPageSuccess: data => {
    return {
      type: actions.UPDATE_TOOLS_PAGE_CONTENT_SUCCESS,
      data,
    };
  },

  updateToolsPageFailed: err => {
    return {
      type: actions.UPDATE_TOOLS_PAGE_CONTENT_FAILED,
      err,
    };
  },
};

export default actions;
