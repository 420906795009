import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import ToolsPageContentTable from './ToolsPageContentTable';
import { PageHeader } from '../../components/page-headers/page-headers';
import { getAllToolsPageContents } from '../../redux/toolsPagesContent/actionCreator';
import { Main } from '../styled';

const ToolsPagesContentList = () => {
  const dispatch = useDispatch();
  const { toolsPageContents, loading } = useSelector(state => ({
    toolsPageContents: state.toolsPagesContent.toolsPageContents,
    loading: state.toolsPagesContent.loading,
  }));


  useEffect(() => {
    dispatch(getAllToolsPageContents());
  }, []);
  return (
    <>
      <PageHeader
        ghost
        title="Tools Page Content"
        subTitle={`${toolsPageContents.length} Tool Page Content`}
        buttons={[]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <ToolsPageContentTable toolsPageContents={toolsPageContents} />
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ToolsPagesContentList;
