import { message } from 'antd';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import { getHeaders } from '../../utils/common';
import errorHandling from '../error';

const {
  getErrorLogBegin,
  getErrorLogFailed,
  getErrorLogSuccess,
  deleteErrorLogBegin,
  deleteErrorLogFailed,
  deleteErrorLogSuccess,
  deleteMultipleErrorLogBegin,
  deleteMultipleErrorLogFailed,
  deleteMultipleErrorLogSuccess,
} = actions;

let cancelToken;
export const getAllErrorLogs = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getErrorLogBegin());
    const res = await axios.get(`error-logs${filter || ''}`, { headers: { ...getHeaders() } });
    dispatch(getErrorLogSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(getErrorLogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    message.error('Error fetching Error Logs');
    return err;
  }
};

export const deleteErrorLog = id => async dispatch => {
  try {
    dispatch(deleteErrorLogBegin());
    const res = await axios.delete(`error-logs/${id}`, { headers: { ...getHeaders() } });
    dispatch(deleteErrorLogSuccess());
    message.success(`Error Log Deleted successfully`);
    return res;
  } catch (err) {
    dispatch(deleteErrorLogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    message.error('Error deleting Errorlog');
    return err;
  }
};

export const deleteMultipleErrorLogs = errorLogIds => async dispatch => {
  try {
    dispatch(deleteMultipleErrorLogBegin());
    const res = await axios.post(
      `error-logs/delete-multiple`,
      { error_log_ids: errorLogIds },
      { headers: { ...getHeaders() } },
    );
    dispatch(deleteMultipleErrorLogSuccess(res));
    message.success(res.data?.msg || 'Error Logs Deleted');
    return res;
  } catch (err) {
    dispatch(deleteMultipleErrorLogFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};
