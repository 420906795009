import React from 'react';
import { Modal } from '../../components/modals/antd-modals';
import { Button } from '../../components/buttons/buttons';
import { IMAGE_URL } from '../../utils/constants';

const ViewPdfModal = ({ visible, onChancel, technicalDoc }) => {
  return technicalDoc ? (
    <Modal
      visible={visible}
      type="primary"
      title={technicalDoc.file_name}
      onCancel={onChancel}
      footer={[
        <div key="view-technical-doc" className="car-modal-footer">
          <Button size="default" type="white" key="black" outlined="true" onClick={onChancel}>
            Cancel
          </Button>
        </div>,
      ]}
    >
      <div className="car-modal" style={{ height: '500px' }}>
        <iframe
          title={technicalDoc.file_name}
          src={`${IMAGE_URL}/${technicalDoc.file_url}#toolbar=0&navpanes=0&scrollbar=0`}
          height="100%"
          width="100%"
        />
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default ViewPdfModal;
