import { message } from 'antd';
import actions from './actions';
import axios, { CancelToken } from '../axios';
import { getHeaders } from '../../utils/common';
import errorHandling from '../error';

const {
  getAllToolsPagesBegin,
  getAllToolsPagesFailed,
  getAllToolsPagesSuccess,
  updateToolsPageBegin,
  updateToolsPageFailed,
  updateToolsPageSuccess,
  getToolsPageBegin,
  getToolsPageFailed,
  getToolsPageSuccess,
} = actions;

let cancelToken;
export const getAllToolsPageContents = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getAllToolsPagesBegin());
    const res = await axios.get(`tools-page-content${filter || ''}`, {
      headers: { ...getHeaders() },
    });
    const { data } = res.data;
    dispatch(getAllToolsPagesSuccess(data));
    return res;
  } catch (err) {
    dispatch(getAllToolsPagesFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

export const getToolsPageContent = destination => async dispatch => {
  try {
    dispatch(getToolsPageBegin());
    const res = await axios.get(`tools-page-content/${destination}`, { headers: { ...getHeaders() } });
    dispatch(getToolsPageSuccess(res.data.data));
    return res;
  } catch (err) {
    dispatch(getToolsPageFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
    return err;
  }
};

export const updateToolsPageContent = (destination, data) => async dispatch => {
  try {
    dispatch(updateToolsPageBegin());
    const res = await axios.put(`tools-page-content/${destination}`, data, {
      headers: { ...getHeaders() },
    });
    dispatch(updateToolsPageSuccess(res.data.data));
    message.success('Tools Page Content updated successfully');
    return res;
  } catch (err) {
    dispatch(updateToolsPageFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};
