import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const { user } = useSelector(state => {
    return {
      user: state.auth.user,
    };
  });

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* FOR DASHBOARD */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
              <FeatherIcon icon="home" />
            </NavLink>
          )
        }
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      {Object.keys(user).length > 0 && user?.role?.name === 'admin' ? (
        <>
          {/* FOR USER  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/users`}>
                  <FeatherIcon icon="users" />
                </NavLink>
              )
            }
            key="user"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
              Users
            </NavLink>
          </Menu.Item>

          {/* FOR CARS + BRAND + MODEL +BODY TYPE */}
          <SubMenu key="car-data" icon={!topMenu && <FeatherIcon icon="truck" />} title="Car">
            {/* FOR CAR BRAND */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/car-brands`}>
                    <FeatherIcon icon="pocket" />
                  </NavLink>
                )
              }
              key="carbrand"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/car-brands`}>
                Car Brand
              </NavLink>
            </Menu.Item>

            {/* FOR CAR MODEL */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/car-models`}>
                    <FeatherIcon icon="zap" />
                  </NavLink>
                )
              }
              key="carmodel"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/car-models`}>
                Car Model
              </NavLink>
            </Menu.Item>

            {/* FOR CAR MODEL */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/car-body-types`}>
                    <FeatherIcon icon="framer" />
                  </NavLink>
                )
              }
              key="carbodytype"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/car-body-types`}>
                Car Body Type
              </NavLink>
            </Menu.Item>

            {/* FOR CAR COLOR */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/car-colors`}>
                    <FeatherIcon icon="bold" />
                  </NavLink>
                )
              }
              key="carcolor"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/car-colors`}>
                Car Color
              </NavLink>
            </Menu.Item>

            {/* FOR CAR  */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/cars`}>
                    <FeatherIcon icon="truck" />
                  </NavLink>
                )
              }
              key="car"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/cars`}>
                Car
              </NavLink>
            </Menu.Item>

            {/* FOR CAR  */}
            {/* <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/offer-cars`}>
                    <FeatherIcon icon="truck" />
                  </NavLink>
                )
              }
              key="offerCar"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/offer-cars`}>
                Offer Car
              </NavLink>
            </Menu.Item> */}

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/price-tool`}>
                    <FeatherIcon icon="briefcase" />
                  </NavLink>
                )
              }
              key="priceTool"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/price-tool`}>
                Price Tool
              </NavLink>
            </Menu.Item>

            {/* FOR CAR INSPECTION REPORT  */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/inspection-reports`}>
                    <FeatherIcon icon="file-text" />
                  </NavLink>
                )
              }
              key="inspection-report"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/inspection-reports`}>
                Inspection Report
              </NavLink>
            </Menu.Item>

            {/* PROMO CARS */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/promo-cars`}>
                    <FeatherIcon icon="check-circle" />
                  </NavLink>
                )
              }
              key="promo-cars"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/promo-cars`}>
                Promo Cars
              </NavLink>
            </Menu.Item>
          </SubMenu>

          {/* FOR BLOGS +BLOG CATEGORY */}
          <SubMenu key="layout" icon={!topMenu && <FeatherIcon icon="layout" />} title="Blogs">
            <Menu.Item key="light">
              <NavLink onClick={toggleCollapsed} to={`${path}/blog-categories`}>
                Category
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dark">
              <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
                Blogs
              </NavLink>
            </Menu.Item>
            {/* Technical Docs */}
            <Menu.Item key="technical-docs">
              <NavLink onClick={toggleCollapsed} to={`${path}/technical-docs`}>
                Technical Docs
              </NavLink>
            </Menu.Item>
          </SubMenu>

          <SubMenu key="homepage" icon={!topMenu && <FeatherIcon icon="sidebar" />} title="Homepage Content">
            <Menu.Item key="light">
              <NavLink onClick={toggleCollapsed} to={`${path}/footer-content`}>
                Footer Content
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dark">
              <NavLink onClick={toggleCollapsed} to={`${path}/stores`}>
                Store
              </NavLink>
            </Menu.Item>
          </SubMenu>

          <SubMenu key="tools" icon={!topMenu && <FeatherIcon icon="tool" />} title="Tools">
            <Menu.Item
              key="price-tracker"
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/fuel-price-tracker`}>
                    <FeatherIcon icon="filter" />
                  </NavLink>
                )
              }
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/fuel-price-tracker`}>
                Fuel Price
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="electricity-price"
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/electricity-price-tracker`}>
                    <FeatherIcon icon="battery-charging" />
                  </NavLink>
                )
              }
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/electricity-price-tracker`}>
                Electricity Price
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="top-car-sales"
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/top-car-sales`}>
                    <FeatherIcon icon="bar-chart-2" />
                  </NavLink>
                )
              }
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/top-car-sales`}>
                Top Sales
              </NavLink>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/tools-page-content`}>
                    <FeatherIcon icon="pen-tool" />
                  </NavLink>
                )
              }
              key="tools-page-content"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/tools-page-content`}>
                Tools Pages
              </NavLink>
            </Menu.Item>
          </SubMenu>

          {/* FOR PAGES  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/pages`}>
                  <FeatherIcon icon="book" />
                </NavLink>
              )
            }
            key="page"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/pages`}>
              Pages
            </NavLink>
          </Menu.Item>

          {/* FOR OUR TEAM  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/our-team`}>
                  <FeatherIcon icon="user-plus" />
                </NavLink>
              )
            }
            key="our-team"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/our-team`}>
              Our Team
            </NavLink>
          </Menu.Item>

          {/* FOR PRESS  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/press`}>
                  <FeatherIcon icon="cast" />
                </NavLink>
              )
            }
            key="press"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/press`}>
              Press
            </NavLink>
          </Menu.Item>

          {/* FOR SELLING CARS  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/selling-cars`}>
                  <FeatherIcon icon="target" />
                </NavLink>
              )
            }
            key="sellingcars"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/selling-cars`}>
              Car Sale Requests
            </NavLink>
          </Menu.Item>

          {/* FOR FAQ + CUSTOMER QUERY */}
          <SubMenu key="customer-query" icon={!topMenu && <FeatherIcon icon="help-circle" />} title="FAQs">
            <Menu.Item key="faq">
              <NavLink onClick={toggleCollapsed} to={`${path}/faqs`}>
                All FAQs
              </NavLink>
            </Menu.Item>
            <Menu.Item key="customer">
              <NavLink onClick={toggleCollapsed} to={`${path}/customer-queries`}>
                Customer Queries
              </NavLink>
            </Menu.Item>
          </SubMenu>

          {/* FOR USER REQUIREMENTS  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/user-requirements`}>
                  <FeatherIcon icon="flag" />
                </NavLink>
              )
            }
            key="user-requirements"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/user-requirements`}>
              User Requirements
            </NavLink>
          </Menu.Item>

          {/* FOR TESTIMONIALS  */}
          {/* <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/testimonials`}>
                  <FeatherIcon icon="book-open" />
                </NavLink>
              )
            }
            key="testimonials"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/testimonials`}>
              Testimonials
            </NavLink>
          </Menu.Item> */}

          {/* FOR CUSTOMER GALLERY  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/gallery`}>
                  <FeatherIcon icon="image" />
                </NavLink>
              )
            }
            key="gallery"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/gallery`}>
              Customer Gallery
            </NavLink>
          </Menu.Item>

          {/* FOR FIANCING +  FINANCING PARTNER */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/financing-partners`}>
                  <FeatherIcon icon="dollar-sign" />
                </NavLink>
              )
            }
            key="financing-partners"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/financing-partners`}>
              Financing Partner
            </NavLink>
          </Menu.Item>
          {/* <SubMenu key="finance" icon={!topMenu && <FeatherIcon icon="dollar-sign" />} title="Financing">
            <Menu.Item key="financing">
              <NavLink onClick={toggleCollapsed} to={`${path}/financings`}>
                Financing
              </NavLink>
            </Menu.Item>
            <Menu.Item key="partners">
              <NavLink onClick={toggleCollapsed} to={`${path}/financing-partners`}>
                Financing Partner
              </NavLink>
            </Menu.Item>
          </SubMenu> */}

          {/* FOR STORE  */}
          {/* <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/stores`}>
                  <FeatherIcon icon="database" />
                </NavLink>
              )
            }
            key="store"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/stores`}>
              Store
            </NavLink>
          </Menu.Item> */}

          {/* FOR SUBSCRIBER  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/subscribers`}>
                  <FeatherIcon icon="user-check" />
                </NavLink>
              )
            }
            key="subscriber"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/subscribers`}>
              Subscribers
            </NavLink>
          </Menu.Item>

          {/* FOR SEARCH QUERY  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/search-inquiries`}>
                  <FeatherIcon icon="search" />
                </NavLink>
              )
            }
            key="search-inquiry"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/search-inquiries`}>
              All Leads
            </NavLink>
          </Menu.Item>

          {/* FOR BOOKING LIST  */}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/bookings`}>
                  <FeatherIcon icon="file-plus" />
                </NavLink>
              )
            }
            key="bookings"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/bookings`}>
              Bookings
            </NavLink>
          </Menu.Item>

          {/* FOR CONTACT INFORMATION  + CONTACT QUERY */}
          <SubMenu key="contact" icon={!topMenu && <FeatherIcon icon="map-pin" />} title="Contact">
            <Menu.Item key="contact-query">
              <NavLink onClick={toggleCollapsed} to={`${path}/contact-query`}>
                Contact Queries
              </NavLink>
            </Menu.Item>
            <Menu.Item key="contact-information">
              <NavLink onClick={toggleCollapsed} to={`${path}/contact-information`}>
                Contact Information
              </NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/error-log`}>
                  <FeatherIcon icon="alert-octagon" />
                </NavLink>
              )
            }
            key="error-log"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/error-log`}>
              Error Log
            </NavLink>
          </Menu.Item>
        </>
      ) : (
        <>
          <SubMenu key="layout" icon={!topMenu && <FeatherIcon icon="layout" />} title="Blogs">
            <Menu.Item key="light">
              <NavLink onClick={toggleCollapsed} to={`${path}/blog-categories`}>
                Category
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dark">
              <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
                Blogs
              </NavLink>
            </Menu.Item>
          </SubMenu>
        </>
      )}

      {/* <Menu.Item key="notification">
        <NavLink onClick={toggleCollapsed} to={`${path}/components/notification`}>
          Notification
        </NavLink>
      </Menu.Item> */}

      {/* {!topMenu && <NavTitle className="sidebar-nav-title">Applications</NavTitle>} */}

      {/* <SubMenu key="profile" icon={!topMenu && <FeatherIcon icon="aperture" />} title="Social App">
        <Menu.Item key="myProfile">
          <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/overview`}>
            My Profile
          </NavLink>
        </Menu.Item>
        <Menu.Item key="profileTimeline">
          <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/timeline`}>
            Timeline
          </NavLink>
        </Menu.Item>
        <Menu.Item key="profileActivity">
          <NavLink onClick={toggleCollapsed} to={`${path}/profile/myProfile/activity`}>
            Activity
          </NavLink>
        </Menu.Item>
      </SubMenu>
       */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/app/calendar/month`}>
              <FeatherIcon icon="calendar" />
            </NavLink>
          )
        }
        key="main-calendar"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/app/calendar/month`}>
          Calendar
        </NavLink>
      </Menu.Item> */}

      {/* <SubMenu key="users" icon={!topMenu && <FeatherIcon icon="users" />} title="Users">
        <Menu.Item key="team">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/team`}>
            Team
          </NavLink>
        </Menu.Item>
        <Menu.Item key="user-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/grid`}>
            Users Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="user-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/list`}>
            Users List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="grid-style">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/grid-style`}>
            Users Grid Style
          </NavLink>
        </Menu.Item>
        <Menu.Item key="grid-group">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/grid-group`}>
            Users Group
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addUser">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/add-user/info`}>
            Add User
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* {!topMenu && <NavTitle className="sidebar-nav-title">CRUD</NavTitle>}
      <SubMenu key="crud" icon={!topMenu && <FeatherIcon icon="database" />} title="Axios">
        <Menu.Item key="axios-view">
          <NavLink onClick={toggleCollapsed} to={`${path}/crud/axios-view`}>
            View All
          </NavLink>
        </Menu.Item>
        <Menu.Item key="axios-add">
          <NavLink onClick={toggleCollapsed} to={`${path}/crud/axios-add`}>
            Add New
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* <SubMenu key="firestore" icon={!topMenu && <FeatherIcon icon="database" />} title="Firestore Crud">
        <Menu.Item key="fbView">
          <NavLink onClick={toggleCollapsed} to={`${path}/firestore/fbView`}>
            View All
          </NavLink>
        </Menu.Item>
        <Menu.Item key="fbAdd">
          <NavLink onClick={toggleCollapsed} to={`${path}/firestore/fbAdd`}>
            Add New
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* {!topMenu && <NavTitle className="sidebar-nav-title">Features</NavTitle>}

      <SubMenu key="components" icon={!topMenu && <FeatherIcon icon="layers" />} title="UI Elements">
        <Menu.Item key="alerts">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/alerts`}>
            Alerts
          </NavLink>
        </Menu.Item>
        <Menu.Item key="auto-complete">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/auto-complete`}>
            AutoComplete
          </NavLink>
        </Menu.Item>
        <Menu.Item key="avatar">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/avatar`}>
            Avatar
          </NavLink>
        </Menu.Item>
        <Menu.Item key="badge">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/badge`}>
            Badge
          </NavLink>
        </Menu.Item>
        <Menu.Item key="breadcrumb">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/breadcrumb`}>
            Breadcrumb
          </NavLink>
        </Menu.Item>
        <Menu.Item key="button">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/button`}>
            Button
          </NavLink>
        </Menu.Item>
        <Menu.Item key="calendar">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/calendar`}>
            Calendar
          </NavLink>
        </Menu.Item>
        <Menu.Item key="cards">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/cards`}>
            Cards
          </NavLink>
        </Menu.Item>
        <Menu.Item key="carousel">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/carousel`}>
            Carousel
          </NavLink>
        </Menu.Item>
        <Menu.Item key="cascader">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/cascader`}>
            Cascader
          </NavLink>
        </Menu.Item>
        <Menu.Item key="checkbox">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/checkbox`}>
            Checkbox
          </NavLink>
        </Menu.Item>
        <Menu.Item key="collapse">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/collapse`}>
            Collapse
          </NavLink>
        </Menu.Item>
        <Menu.Item key="comments">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/comments`}>
            Comments
          </NavLink>
        </Menu.Item>
        <Menu.Item key="base">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/base`}>
            Dashboard Base
          </NavLink>
        </Menu.Item>
        <Menu.Item key="date-picker">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/date-picker`}>
            DatePicker
          </NavLink>
        </Menu.Item>
        <Menu.Item key="drag">
          <NavLink to="/admin/components/drag">Drag & Drop</NavLink>
        </Menu.Item>
        <Menu.Item key="drawer">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/drawer`}>
            Drawer
          </NavLink>
        </Menu.Item>
        <Menu.Item key="dropdown">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/dropdown`}>
            Dropdown
          </NavLink>
        </Menu.Item>
        <Menu.Item key="empty">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/empty`}>
            Empty
          </NavLink>
        </Menu.Item>
        <Menu.Item key="component-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/grid`}>
            Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="input">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/input`}>
            Input
          </NavLink>
        </Menu.Item>
        <Menu.Item key="component-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/list`}>
            List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/menu`}>
            Menu
          </NavLink>
        </Menu.Item>
        <Menu.Item key="message">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/message`}>
            Message
          </NavLink>
        </Menu.Item>
        <Menu.Item key="modals">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/modals`}>
            Modals
          </NavLink>
        </Menu.Item>
        
        <Menu.Item key="page-headers">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/page-headers`}>
            Page Headers
          </NavLink>
        </Menu.Item>
        <Menu.Item key="pagination">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/pagination`}>
            Paginations
          </NavLink>
        </Menu.Item>
        <Menu.Item key="confirme">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/confirm`}>
            Popconfirme
          </NavLink>
        </Menu.Item>
        <Menu.Item key="popover">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/popover`}>
            Popover
          </NavLink>
        </Menu.Item>
        <Menu.Item key="progress">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/progress`}>
            Progress
          </NavLink>
        </Menu.Item>
        <Menu.Item key="radio">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/radio`}>
            Radio
          </NavLink>
        </Menu.Item>
        <Menu.Item key="rate">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/rate`}>
            Rate
          </NavLink>
        </Menu.Item>
        <Menu.Item key="result">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/result`}>
            Result
          </NavLink>
        </Menu.Item>
        <Menu.Item key="select">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/select`}>
            Select
          </NavLink>
        </Menu.Item>
        <Menu.Item key="skeleton">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/skeleton`}>
            Skeleton
          </NavLink>
        </Menu.Item>
        <Menu.Item key="slider">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/slider`}>
            Slider
          </NavLink>
        </Menu.Item>
        <Menu.Item key="spiner">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/spiner`}>
            Spiner
          </NavLink>
        </Menu.Item>
        <Menu.Item key="statistic">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/statistic`}>
            Statistic
          </NavLink>
        </Menu.Item>
        <Menu.Item key="steps">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/steps`}>
            Steps
          </NavLink>
        </Menu.Item>
        <Menu.Item key="switch">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/switch`}>
            Switch
          </NavLink>
        </Menu.Item>
        <Menu.Item key="tags">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/tags`}>
            Tags
          </NavLink>
        </Menu.Item>
        <Menu.Item key="timeline">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/timeline`}>
            Timeline
          </NavLink>
        </Menu.Item>
        <Menu.Item key="timepicker">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/timepicker`}>
            Timepicker
          </NavLink>
        </Menu.Item>
        <Menu.Item key="tree-select">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/tree-select`}>
            TreeSelect
          </NavLink>
        </Menu.Item>
        <Menu.Item key="upload">
          <NavLink onClick={toggleCollapsed} to={`${path}/components/upload`}>
            Upload
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* <SubMenu
        key="forms"
        icon={!topMenu && <FeatherIcon icon="disc" />}
        title={
          <>
            <span className="pl-0">
              Forms<span className="badge badge-success">New</span>
            </span>
          </>
        }
      >
        <Menu.Item key="form-layout">
          <NavLink onClick={toggleCollapsed} to={`${path}/form-layout`}>
            Form Layouts
          </NavLink>
        </Menu.Item>
        <Menu.Item key="form-elements">
          <NavLink onClick={toggleCollapsed} to={`${path}/form-elements`}>
            Form Elements
          </NavLink>
        </Menu.Item>
        <Menu.Item key="form-components">
          <NavLink onClick={toggleCollapsed} to={`${path}/form-components`}>
            Form Components
          </NavLink>
        </Menu.Item>
        <Menu.Item key="form-validation">
          <NavLink onClick={toggleCollapsed} to={`${path}/form-validation`}>
            Form Validation
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* <SubMenu key="wizards" icon={!topMenu && <FeatherIcon icon="square" />} title="Wizards">
        <Menu.Item key="wizard-one">
          <NavLink onClick={toggleCollapsed} to={`${path}/wizards/one`}>
            Wizard 1
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wizard-two">
          <NavLink onClick={toggleCollapsed} to={`${path}/wizards/two`}>
            Wizard 2
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wizard-three">
          <NavLink onClick={toggleCollapsed} to={`${path}/wizards/three`}>
            Wizard 3
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wizard-four">
          <NavLink onClick={toggleCollapsed} to={`${path}/wizards/four`}>
            Wizard 4
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wizard-five">
          <NavLink onClick={toggleCollapsed} to={`${path}/wizards/five`}>
            Wizard 5
          </NavLink>
        </Menu.Item>
        <Menu.Item key="wizard-six">
          <NavLink onClick={toggleCollapsed} to={`${path}/wizards/six`}>
            Wizard 6
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* 
      <SubMenu key="icons" icon={!topMenu && <FeatherIcon icon="grid" />} title="Icons">
        <Menu.Item key="feathers">
          <NavLink onClick={toggleCollapsed} to={`${path}/icons/feathers`}>
            Feather icons (svg)
          </NavLink>
        </Menu.Item>
        <Menu.Item key="font-awesome">
          <NavLink onClick={toggleCollapsed} to={`${path}/icons/font-awesome`}>
            Font Awesome
          </NavLink>
        </Menu.Item>
        <Menu.Item key="antd">
          <NavLink onClick={toggleCollapsed} to={`${path}/icons/antd`}>
            Ant Design icons
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/editor`}>
              <FeatherIcon icon="edit" />
            </NavLink>
          )
        }
        key="editor"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/editor`}>
          Editor
        </NavLink>
      </Menu.Item> */}

      {/* <SubMenu key="maps" icon={!topMenu && <FeatherIcon icon="map" />} title="Maps">
        <Menu.Item key="google">
          <NavLink onClick={toggleCollapsed} to={`${path}/maps/google`}>
            Google Maps
          </NavLink>
        </Menu.Item>
        <Menu.Item key="leaflet">
          <NavLink onClick={toggleCollapsed} to={`${path}/maps/leaflet`}>
            Leaflet Maps
          </NavLink>
        </Menu.Item>
        <Menu.Item key="Vector">
          <NavLink onClick={toggleCollapsed} to={`${path}/maps/Vector`}>
            Simple Map
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* {!topMenu && <NavTitle className="sidebar-nav-title">Pages</NavTitle>}

      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/settings`}>
              <FeatherIcon icon="settings" />
            </NavLink>
          )
        }
        key="settings"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
          Settings
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/pricing`}>
              <FeatherIcon icon="dollar-sign" />
            </NavLink>
          )
        }
        key="pricing"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/pricing`}>
          Pricing
        </NavLink>
      </Menu.Item> */}
      {/* 
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/banners`}>
              <FeatherIcon icon="cast" />
            </NavLink>
          )
        }
        key="banners"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/banners`}>
          Banners
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/faq`}>
              <FeatherIcon icon="help-circle" />
            </NavLink>
          )
        }
        key="faq"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/faq`}>
          FAQs
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/search`}>
              <FeatherIcon icon="search" />
            </NavLink>
          )
        }
        key="search"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/search`}>
          Search Results
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/starter`}>
              <FeatherIcon icon="circle" />
            </NavLink>
          )
        }
        key="starter"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/starter`}>
          Blank Page
        </NavLink>
      </Menu.Item> */}
      {/* <Menu.Item
        icon={
          !topMenu && <ReactSVG className="sDash_menu-item-icon" src={require('../static/img/icon/headphone.svg')} />
        }
        key="support"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/support`}>
          Support Center
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="airplay" />} key="maintenance">
        <NavLink onClick={toggleCollapsed} to={`${path}/maintenance`}>
          Maintenance
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/404`}>
              <FeatherIcon icon="info" />
            </NavLink>
          )
        }
        key="404"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/404`}>
          404
        </NavLink>
      </Menu.Item> */}

      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/comingSoon`}>
              <FeatherIcon icon="clock" />
            </NavLink>
          )
        }
        key="comingSoon"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/comingSoon`}>
          Coming Soon
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
