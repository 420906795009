import React from 'react';
import { Button, Col, Modal } from 'antd';
import { Formik, Form } from 'formik';
//
import { technicalDocsSchema } from './technical-docs.schema';
import { BasicFormWrapper } from '../styled';
import FormInput from '../../components/input/input';
import FormUpload from '../../components/upload/FormUpload';

const AddTechnicalDocsModal = ({ visible, onCancel, handleSubmit }) => {
  return (
    <Modal type="primary" title="Add Technical Documentation" footer={null} onCancel={onCancel} visible={visible}>
      <div className="brand-modal">
        <Formik
          validationSchema={technicalDocsSchema}
          initialValues={{
            file_name: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            handleSubmit(values, resetForm);
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue, resetForm }) => {
            return (
              <Form>
                <BasicFormWrapper>
                  <div className="add-product-block">
                    <Col xs={24}>
                      <FormInput
                        type="text"
                        name="file_name"
                        label="File Name"
                        value={values.file_name}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col xs={24}>
                      <div style={{ marginTop: '16px' }}>
                        <FormUpload
                          name="pdf-file"
                          label="File"
                          value={values['pdf-file']}
                          accept="application/pdf"
                          handleChange={info => {
                            const fileList = [...info.fileList].slice(-1);
                            setFieldValue('pdf-file', fileList.length > 0 ? [fileList[0]] : null);
                          }}
                        />
                      </div>
                    </Col>
                    <div style={{ height: '16px' }} />
                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        style={{
                          backgroundColor: '#155BDC',
                          color: '#fff',
                          marginRight: 5,
                          height: '40px',
                          fontWeight: '500',
                        }}
                        htmlType="submit"
                      >
                        Add Technical Document
                      </Button>
                      <Button
                        size="default"
                        type="white"
                        style={{
                          marginRight: 5,
                          height: '40px',
                          fontWeight: '500',
                        }}
                        key="back"
                        outlined="true"
                        onClick={() => {
                          resetForm();
                          onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </div>
                </BasicFormWrapper>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddTechnicalDocsModal;
