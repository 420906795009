// import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import moment from 'moment';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

export { ellipsis };

export function validatePhoneNumber(argum) {
  const { fieldName, fieldValue, setFieldValue, errors, setErrors, errorMsg } = argum;
  // const numberReg = new RegExp(/^[-+]?[0-9]\d*(\.\d+)?$/);
  const numberReg = new RegExp(/^[0-9\s]*$/);
  // const trimmedNum = fieldValue.trim();
  const trimmedNum = fieldValue;
  if (numberReg.test(String(trimmedNum)) || trimmedNum === '') {
    setFieldValue(fieldName, trimmedNum);
  } else {
    setErrors({
      ...errors,
      number: errorMsg ?? 'Number is invalid!',
    });
  }
}

// eslint-disable-next-line no-unused-vars
export function validateEditorData(state, fieldName, setFieldValue) {
  // const isFieldEmpty = convertToHTML(state.getCurrentContent());
  // if (isFieldEmpty === '<p></p>') {
  //   setFieldValue(fieldName, null);
  //   return;
  // }
  // setFieldValue(fieldName, state);
}

export function convertToEditorFormat(text) {
  return EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(text || '')));
}

export function convertToDateInForm(apiDateData) {
  const dateArr = new Date(apiDateData).toLocaleDateString().split('/');

  const convertedDate = `${dateArr[2]}-${dateArr[0] < 10 ? `0${dateArr[0]}` : dateArr[0]}-${
    dateArr[1] < 10 ? `0${dateArr[1]}` : dateArr[1]
  }`;

  return convertedDate;
}

export const formatDate = date => {
  return moment(date).format('YYYY-MM-DD');
};
export function addImageFallback(event) {
  event.currentTarget.src = '/noimage.png';
}

export function formatMemorySize(bytes, decimals = 2) {
  if (!bytes) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
}

export const formatNumber = num => {
  return new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 2,
  }).format(num);
};
