const actions = {
  GET_TOP_CAR_SALES_BEGIN: 'GET_TOP_CAR_SALES_BEGIN',
  GET_TOP_CAR_SALES_SUCCESS: 'GET_TOP_CAR_SALES_SUCCESS',
  GET_TOP_CAR_SALES_FAILED: 'GET_TOP_CAR_SALES_FAILED',

  ADD_TOP_CAR_SALES_BEGIN: 'ADD_TOP_CAR_SALES_BEGIN',
  ADD_TOP_CAR_SALES_SUCCESS: 'ADD_TOP_CAR_SALES_SUCCESS',
  ADD_TOP_CAR_SALES_FAILED: 'ADD_TOP_CAR_SALES_FAILED',

  UPDATE_TOP_CAR_SALES_BEGIN: 'UPDATE_TOP_CAR_SALES_BEGIN',
  UPDATE_TOP_CAR_SALES_SUCCESS: 'UPDATE_TOP_CAR_SALES_SUCCESS',
  UPDATE_TOP_CAR_SALES_FAILED: 'UPDATE_TOP_CAR_SALES_FAILED',

  DELETE_FUEL_PRICE_BEGIN: 'DELETE_FUEL_PRICE_BEGIN',
  DELETE_FUEL_PRICE_SUCCESS: 'DELETE_FUEL_PRICE_SUCCESS',
  DELETE_FUEL_PRICE_FAILED: 'DELETE_FUEL_PRICE_FAILED',

  getTopCarSalesBegin: () => {
    return {
      type: actions.GET_TOP_CAR_SALES_BEGIN,
    };
  },

  getTopCarSalesSuccess: data => {
    return {
      type: actions.GET_TOP_CAR_SALES_SUCCESS,
      data,
    };
  },

  getTopCarSalesFailed: err => {
    return {
      type: actions.GET_TOP_CAR_SALES_FAILED,
      err,
    };
  },

  addTopCarSalesBegin: () => {
    return {
      type: actions.ADD_TOP_CAR_SALES_BEGIN,
    };
  },

  addTopCarSalesSuccess: data => {
    return {
      type: actions.ADD_TOP_CAR_SALES_SUCCESS,
      data,
    };
  },

  addTopCarSalesFailed: err => {
    return {
      type: actions.ADD_TOP_CAR_SALES_FAILED,
      err,
    };
  },

  updateTopCarSalesBegin: () => {
    return {
      type: actions.UPDATE_TOP_CAR_SALES_BEGIN,
    };
  },

  updateTopCarSalesSuccess: data => {
    return {
      type: actions.UPDATE_TOP_CAR_SALES_SUCCESS,
      data,
    };
  },

  updateTopCarSalesFailed: err => {
    return {
      type: actions.UPDATE_TOP_CAR_SALES_FAILED,
      err,
    };
  },

  deleteTopCarSalesBegin: () => {
    return {
      type: actions.DELETE_FUEL_PRICE_SUCCESS,
    };
  },

  deleteTopCarSalesSuccess: data => {
    return {
      type: actions.DELETE_FUEL_PRICE_SUCCESS,
      data,
    };
  },

  deleteTopCarSalesFailed: err => {
    return {
      type: actions.DELETE_FUEL_PRICE_FAILED,
      err,
    };
  },
};

export default actions;