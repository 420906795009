import { message } from 'antd';
//
import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getTopCarSalesBegin,
  getTopCarSalesFailed,
  getTopCarSalesSuccess,
  addTopCarSalesBegin,
  addTopCarSalesFailed,
  addTopCarSalesSuccess,
  updateTopCarSalesBegin,
  updateTopCarSalesFailed,
  updateTopCarSalesSuccess,
  deleteTopCarSalesBegin,
  deleteTopCarSalesFailed,
  deleteTopCarSalesSuccess,
} = actions;

let cancelToken;
export const getTopCarSales = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getTopCarSalesBegin());
    const res = await axios.get(`top-car-sales${filter ? filter : ''}`, {
      cancelToken: cancelToken?.token,
      headers: { ...getHeaders() },
    });
    dispatch(
      getTopCarSalesSuccess({
        topCarSales: res.data?.topCarSales,
        total: res.data?.total,
        currentPage: res.data?.currentPage,
        pageLimit: res.data?.pageLimit,
      }),
    );

    return res.data;
  } catch (err) {
    dispatch(getTopCarSalesFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

export const addTopCarSales = formData => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addTopCarSalesBegin());
    const res = await axios.post('top-car-sales', formData, {
      headers: { ...getHeaders() },
    });
    const newTopCarSales = [res.data, ...state.topCarSales.topCarSales];
    dispatch(addTopCarSalesSuccess(newTopCarSales));
    message.success('Car Sale Data added successfully');
    return res;
  } catch (err) {
    dispatch(addTopCarSalesFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
    return err;
  }
};

export const editTopCarSale = (id, data) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateTopCarSalesBegin());
    const res = await axios.put(`top-car-sales/${id}`, data, {
      headers: { ...getHeaders() },
    });
    const updatedTopCarSale = state.topCarSales.topCarSales.map(carSale => {
      if (carSale._id === id) return res.data;
      return carSale;
    });
    dispatch(updateTopCarSalesSuccess(updatedTopCarSale));
    message.success('Car Sale updated successfully');
    return res;
  } catch (err) {
    dispatch(updateTopCarSalesFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

export const deleteTopCarSale = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteTopCarSalesBegin());
    await axios.delete(`top-car-sales/${id}`, { headers: { ...getHeaders() } });
    dispatch(deleteTopCarSalesSuccess(state.topCarSales.topCarSales.filter(carSale => carSale._id !== id)));
    message.success('Car Sale Deleted successfully');
  } catch (err) {
    dispatch(deleteTopCarSalesFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};
