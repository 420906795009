import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import FeatherIcon from 'feather-icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import Cookies from 'js-cookie';
//
import { getToolsPageContent } from '../../../redux/toolsPagesContent/actionCreator';
import { Button } from '../../../components/buttons/buttons';
import { DEFAULT_LANG } from '../../../utils/constants';

function contentAccordingToLang(data) {
  if (!data.length) return null;

  const currentLang = Cookies.get('lang') || DEFAULT_LANG?.value;
  return (
    data.find(el => el.lang === currentLang) ||
    data.find(el => el.lang === 'en') ||
    data.find(el => el.lang) || // Get the first valid entry
    data[0] // Just in case there's any undefined or null entry
  );
}

const ViewToolsPageContent = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentToolPageContent, loading } = useSelector(state => {
    return {
      currentToolPageContent: state.toolsPagesContent.currentToolPageContent,
      loading: state.toolsPagesContent.loading,
    };
  });

  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  useEffect(() => {
    if (match.params.destination) dispatch(getToolsPageContent(match.params.destination));
  }, [match.params.destination]);

  if (loading)
    return (
      <div className="spin">
        <Spin />
      </div>
    );

  return (
    <div style={{ padding: 20, backgroundColor: '#fff' }}>
      <h2 as={'h4'} style={{ textAlign: 'center', fontSize: 30, padding: 10, fontWeight: 'bold' }}>
        {currentToolPageContent?.name}
      </h2>
      <br />
      <div
        className="info"
        style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <p style={{ marginBottom: 0 }}>
          <span style={{ fontWeight: 'bold' }}>Page:</span> {currentToolPageContent?.destination}{' '}
        </p>
        <Button
          key="1"
          style={{ backgroundColor: 'grey', color: '#fff' }}
          size="default"
          onClick={() => history.push(`/admin/tools-page-content`)}
        >
          <FeatherIcon icon="chevron-left" size={16} /> Back to List
        </Button>
      </div>
      <br />
      <div
        className="preview"
        dangerouslySetInnerHTML={createMarkup(
          contentAccordingToLang(currentToolPageContent?.details || [])?.description,
        )}
      />
      I
    </div>
  );
};

export default ViewToolsPageContent;
