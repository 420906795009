const actions = {
  GET_PROMO_CAR_BEGIN: 'GET_PROMO_CAR_BEGIN',
  GET_PROMO_CAR_SUCCESS: 'GET_PROMO_CAR_SUCCESS',
  GET_PROMO_CAR_FAIL: 'GET_PROMO_CAR_FAIL',

  REMOVE_PROMO_CAR_BEGIN: 'GET_PROMO_CAR_BEGIN',
  REMOVE_PROMO_CAR_SUCCESS: 'REMOVE_PROMO_CAR_SUCCESS',
  REMOVE_PROMO_CAR_FAIL: 'REMOVE_PROMO_CAR_FAIL',

  REORDER_PROMO_CAR_BEGIN: 'REORDER_PROMO_CAR_BEGIN',
  REORDER_PROMO_CAR_SUCCESS: 'REORDER_PROMO_CAR_SUCCESS',
  REORDER_PROMO_CAR_FAIL: 'REORDER_PROMO_CAR_FAIL',

  getPromoCarsBegin: () => {
    return {
      type: actions.GET_PROMO_CAR_BEGIN,
    };
  },

  getPromoCarsSuccess: data => {
    return {
      type: actions.GET_PROMO_CAR_SUCCESS,
      data,
    };
  },

  getPromoCarsFail: err => {
    return {
      type: actions.GET_PROMO_CAR_FAIL,
      err,
    };
  },

  removePromoCarsBegin: () => {
    return {
      type: actions.REMOVE_PROMO_CAR_BEGIN,
    };
  },

  removePromoCarsSuccess: data => {
    return {
      type: actions.REMOVE_PROMO_CAR_SUCCESS,
      data,
    };
  },

  removePromoCarFail: err => {
    return {
      type: actions.REMOVE_PROMO_CAR_FAIL,
      err,
    };
  },

  reorderPromoCarsBegin: () => {
    return {
      type: actions.REORDER_PROMO_CAR_BEGIN,
    };
  },

  reorderPromoCarsSuccess: data => {
    return {
      type: actions.REORDER_PROMO_CAR_SUCCESS,
      data,
    };
  },

  reorderPromoCarsFail: err => {
    return {
      type: actions.REORDER_PROMO_CAR_FAIL,
      err,
    };
  },
};

export default actions;
