import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
//
import ElectricityPriceTable from './ElectricityPriceTable';
import CreateElectricityModal from './CreateElectricityModal';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import {
  addElectricityPrice,
  deleteElectricityPrice,
  editElectricityPrice,
  getElectricityPrices,
} from '../../redux/electricityPrices/actionCreator';

const ElectricityPrices = () => {
  const dispatch = useDispatch();

  const [queryState, setQueryState] = useState({ visible: false, currentPage: 1, pageLimit: 10 });

  // show add Price Modal
  const showModal = () => setQueryState(prev => ({ ...prev, visible: true }));

  // hide add Price Modal
  const onCancel = () => setQueryState(prev => ({ ...prev, visible: false }));

  const handleAdd = async (formData, resetForm) => {
    const res = await dispatch(addElectricityPrice(formData));
    if (res?.data?.status) {
      resetForm();
      setQueryState(prev => ({ ...prev, visible: false }));
      dispatch(getElectricityPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
    }
  };

  const handleEdit = (id, data) => {
    dispatch(editElectricityPrice(id, data));
    getElectricityPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`);
  };

  const handleDelete = id => {
    dispatch(deleteElectricityPrice(id));
    getElectricityPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`);
  };

  const { currentPage, electricityPrices, loading, pageLimit, totalPrices } = useSelector(state => {
    return {
      electricityPrices: state.electricityPrice.electricityPrices,
      currentPage: state.electricityPrice.currentPage,
      pageLimit: state.electricityPrice.pageLimit,
      totalPrices: state.electricityPrice.total,
      loading: state.electricityPrice.loading,
    };
  });

  useEffect(() => {
    dispatch(getElectricityPrices(`?page=${queryState.currentPage}&limit=${queryState.pageLimit}`));
  }, [queryState.currentPage, queryState.pageLimit]);

  return (
    <>
      <PageHeader
        ghost
        title="Electricity Price Tracker"
        subTitle={totalPrices > 0 && <span className="title-counter">{totalPrices} Electricity Prices </span>}
        buttons={[
          <Button onClick={showModal} key="1" style={{ backgroundColor: '#155BDC', color: '#fff' }} size="default">
            <FeatherIcon icon="plus" size={16} />
            Add New
          </Button>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24}>
            {loading ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <ElectricityPriceTable
                prices={electricityPrices}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalPrices={totalPrices}
                setParentState={setQueryState}
              />
            )}
          </Col>
        </Row>
        <CreateElectricityModal visible={queryState.visible} onCancel={onCancel} handleSubmit={handleAdd} />
      </Main>
    </>
  );
};

export default ElectricityPrices;
