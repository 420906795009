import React, { useState } from 'react';
import { message, Table } from 'antd';
import FeatherIcon from 'feather-icons-react';
//
import EditTechnicalDocModal from './EditTechnicalDocsModal';
import DeleteTechnicalDocsModal from './DeleteTechnicalDocsModal';
import ViewPdfModal from './ViewPdfModal';
import { UserTableStyleWrapper } from '../pages/style';
import { TableWrapper } from '../styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { IMAGE_URL } from '../../utils/constants';
import { formatMemorySize } from '../../utility/utility';

const TechnicalDocsColumns = [
  {
    title: 'SN',
    dataIndex: 'sNo',
    key: 'sNo',
  },
  {
    title: 'File Name',
    dataIndex: 'file_name',
    key: 'file_name',
  },
  {
    title: 'Size',
    dataIndex: 'file_size',
    key: 'file_size',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const TechnicalDocsTable = ({ technicalDocs, handleDelete, handleEdit, currentPage, total, pageLimit, setParentState }) => {
  const [state, setState] = useState({ editModelVisible: false, deleteModelVisible: false, viewPDFVisible: false });
  const [technicalDoc, setTechnicalDoc] = useState({});

  const showModal = (id, type) => {
    const tempTechnicalDoc = technicalDocs.find(doc => doc._id === id);
    if (technicalDocs) {
      if (type === 'edit') setState({ ...state, editModelVisible: true });
      else if (type === 'viewPDFVisible') setState({ ...state, viewPDFVisible: true });
      else setState({ ...state, deleteModelVisible: true });
      setTechnicalDoc(tempTechnicalDoc);
    }
  };

  const handleCancel = () => {
    setTechnicalDoc({});
    setState({ ...state, editModelVisible: false, deleteModelVisible: false, viewPDFVisible: false });
  };

  const handleDeleteConfirmation = docId => {
    handleDelete(docId);
    setTechnicalDoc(null);
    setState({ deleteModelVisible: false, editModelVisible: false });
  };

  const handleEditSubmit = (docId, formData) => {
    handleEdit(docId, formData);
    setTechnicalDoc(null);
    setState({ deleteModelVisible: false, editModelVisible: false });
  };

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(`${IMAGE_URL}/${fileUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${fileName}.pdf` || 'downloaded-file';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      message.error('Failed to Download File');
    }
  };

  const newTechnicalDocsTypes = [];

  technicalDocs?.map((doc, index) => {
    const { _id, file_name, file_url, file_size } = doc;
    return newTechnicalDocsTypes.push({
      key: _id,
      sNo: (
        <figure>
          <p>{index + 1}</p>
        </figure>
      ),
      file_name: (
        <div className="user-info">
          <Heading className="user-name" as="h6">
            {' '}
            {file_name}
          </Heading>
        </div>
      ),
      file_size: <span>{formatMemorySize(file_size)}</span>,
      action: (
        <div className="table-actions">
          <Button
            className="icon-link"
            type="info"
            target="_blank"
            shape="circle"
            onClick={() => handleDownload(file_url, file_name)}
          >
            <FeatherIcon icon="download" size={16} />
          </Button>
          <Button
            className="btn-icon"
            type="info"
            to="#"
            shape="circle"
            onClick={() => showModal(_id, 'viewPDFVisible')}
          >
            <FeatherIcon icon="eye" size={16} />
          </Button>
          <Button className="btn-icon" type="info" to="#" shape="circle" onClick={() => showModal(_id, 'edit')}>
            <FeatherIcon icon="edit" size={16} />
          </Button>
          <Button className="btn-icon" type="danger" to="#" shape="circle" onClick={() => showModal(_id, 'delete')}>
            <FeatherIcon icon="trash-2" size={16} />
          </Button>
        </div>
      ),
    });
  });

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            dataSource={newTechnicalDocsTypes}
            columns={TechnicalDocsColumns}
            pagination={{
              current: currentPage,
              defaultPageSize: pageLimit,
              total,
              showSizeChanger: true,
              showTotal: (totalItem, range) => `${range[0]}-${range[1]} of ${totalItem} items`,
            }}
            onChange={data =>
              setParentState(prev => ({ ...prev, currentPage: data.current, pageLimit: data.pageSize }))
            }
          />
        </TableWrapper>
      </UserTableStyleWrapper>
      {Object.keys(technicalDocs).length > 0 && (
        <>
          {state.editModelVisible && technicalDoc && (
            <EditTechnicalDocModal
              visible={state.editModelVisible}
              onCancel={handleCancel}
              handleEdit={handleEditSubmit}
              technicalDoc={{
                _id: technicalDoc._id,
                file_name: technicalDoc.file_name,
                'pdf-file': [
                  {
                    uid: 1,
                    name: technicalDoc.original_file_name,
                    status: 'done',
                    url: `${IMAGE_URL}/${technicalDoc.file_url}`,
                  },
                ],
              }}
              technicalDocId={technicalDoc._id}
            />
          )}
          {state.deleteModelVisible && technicalDoc && (
            <DeleteTechnicalDocsModal
              handleDelete={handleDeleteConfirmation}
              onCancel={handleCancel}
              technicalDoc={technicalDoc}
              visible={state.deleteModelVisible}
            />
          )}
          {state.viewPDFVisible && technicalDoc && (
            <ViewPdfModal visible={state.viewPDFVisible} onChancel={handleCancel} technicalDoc={technicalDoc} />
          )}
        </>
      )}
    </Cards>
  );
};

export default TechnicalDocsTable;
