import React, { createContext, useContext, useMemo } from 'react';
import { Badge, Checkbox, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
//
import { Cards } from '../../../components/cards/frame/cards-frame';
import { reOrderPromoCars } from '../../../redux/promoCars/actionCreator';
import { IMAGE_URL } from '../../../utils/constants';
import { DragGrid } from '../../../components/utilities/icons';
import { addImageFallback } from '../../../utility/utility';

const Columns = [
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: 'Name',
    dataIndex: 'car_name',
    key: 'car_name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'KM Driven',
    dataIndex: 'mileage',
    key: 'mileage',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
];

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});
const DragIcon = () => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <div ref={ref} {...listeners} {...attributes} style={{ cursor: 'grab', color: '#999' }}>
      <DragGrid icon="grid" size={14} />
    </div>
  );
};

const TableRow = ({ children, record, className, style, ...restProps }) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: restProps['data-row-key'],
  });
  const context = useMemo(() => ({ attributes, listeners, ref: setActivatorNodeRef }), [
    attributes,
    listeners,
    setActivatorNodeRef,
  ]);

  return (
    <SortableItemContext.Provider value={context}>
      <tr
        ref={setNodeRef}
        className={`${className} custom-row`}
        style={{ ...style, transform: CSS.Transform.toString(transform), transition }}
        {...restProps}
      >
        {children}
      </tr>
    </SortableItemContext.Provider>
  );
};

const PromoCarTable = ({ promoCars, selectedCars, setSelectedCars }) => {
  const promoCarTables = [];
  const dispatch = useDispatch();

  const handleDragEnd = async event => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = promoCars.findIndex(item => item._id === active.id);
    const newIndex = promoCars.findIndex(item => item._id === over.id);

    const newItems = arrayMove(promoCars, oldIndex, newIndex);
    dispatch(reOrderPromoCars(newItems.map((el, index) => ({ ...el, order_index: index + 1 }))));
  };

  const handleCarCheck = carid => {
    const carIndex = selectedCars.findIndex(el => el === carid);
    const temp = [...selectedCars];
    if (carIndex === -1) temp.push(carid);
    else temp.splice(carIndex, 1);
    setSelectedCars(temp);
  };

  if (promoCars)
    promoCars.map(car => {
      return promoCarTables.push({
        key: car._id,
        actions: (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' }}>
            <DragIcon id={car._id} />
            <Checkbox
              name="promo-car"
              checked={selectedCars.includes(car._id)}
              onChange={() => handleCarCheck(car._id)}
            />
          </div>
        ),
        image: (
          <img
            src={car.images ? `${IMAGE_URL}/${JSON.parse(car.images)[0]}` : require(`../../../static/img/noimage.png`)}
            alt={`img${car._id}`}
            onError={addImageFallback}
            height={50}
            width={50}
            style={{ objectFit: 'cover', borderRadius: '5px' }}
          />
        ),
        car_name: (
          <Link
            to={`/admin/cars/car-detail/${car._id}`}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {car.name}
          </Link>
        ),
        status: (
          <Badge
            style={{
              textTransform: 'capitalize',
              backgroundColor: car.status === 'sold' ? '#ff0000' : car.status === 'draft' ? '#155BDC' : '#0F4D0F',
            }}
            count={car.status}
          />
        ),
        mileage: <span>{car.mileage} KM</span>,
        brand: <span>{car.brand_name}</span>,
        price: (
          <span>
            {new Intl.NumberFormat('fr-FR').format(car.price)} {process.env.REACT_APP_DEFAULT_CURRENCY}
          </span>
        ),
      });
    });

  return (
    <Cards headless>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={promoCars.map(el => ({ ...el, id: el._id }))} strategy={verticalListSortingStrategy}>
          <Table
            dataSource={promoCarTables}
            columns={Columns}
            onRow={record => record}
            components={{
              body: {
                row: TableRow,
              },
            }}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Cards>
  );
};

export default PromoCarTable;
