import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Select } from 'antd';
import { FieldArray, Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
//
import { BasicFormWrapper } from '../styled';
import FormInput from '../../components/input/input';
import { getAllModelsByBrand } from '../../redux/carmodel/actionCreator';

const EditTopCarSale = ({ visible, onCancel, handleEdit, carSale, carSaleId }) => {
  const [brand_models, setBrandModels] = useState([]);

  const dispatch = useDispatch();
  const { brands, models } = useSelector(state => {
    return {
      brands: state.carBrand.brands,
      models: state.carModel.modelsByBrand,
    };
  });
  const handleChangeBrand = async (id, index) => {
    if (id) {
      const res = await dispatch(getAllModelsByBrand(id));
      const temp = [...brand_models];
      if (res?.data?.carModels) temp[index] = res.data.carModels;
      else temp[index] = [];
      setBrandModels(temp);
    }
  };

  const handleCarSaleItemChange = (carSaleData, setFieldValue, index, value, key) => {
    const temp = [...carSaleData];
    if (!temp[index]) return;
    const currentItem = JSON.parse(JSON.stringify(temp[index]));
    currentItem[key] = value;
    temp[index] = currentItem;
    setFieldValue('car_sales', temp);
  };

  useEffect(() => {
    const tempModelBrands = [];
    const carSalesPromises = carSale?.car_sales?.map(async (sale, index) => {
      const res = await dispatch(getAllModelsByBrand(sale.brand));
      if (res?.data?.carModels) tempModelBrands[index] = res.data.carModels;
    });
    if (carSalesPromises) {
      Promise.all(carSalesPromises).then(() => {
        setBrandModels(tempModelBrands);
      });
    }
  }, []);

  return (
    <Modal type="primary" title="Update Top Car Sale" footer={null} onCancel={onCancel} visible={visible}>
      <div className="brand-modal">
        <Formik initialValues={carSale} onSubmit={formData => handleEdit(carSaleId, formData)}>
          {({ values, errors, touched, handleChange, setFieldValue, resetForm }) => {
            return (
              <Form>
                <BasicFormWrapper>
                  <div className="add-product-block">
                    <Col xs={24}>
                      <FormInput
                        type="year"
                        name="year"
                        label="Year"
                        value={values.year}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col xs={24}>
                      <div>
                        <FieldArray
                          name="car_sales"
                          render={() => {
                            return (
                              <Row gutter={[0, 8]} style={{ marginTop: '16px' }}>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={8} className="input-required">
                                      <div style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>Brand</div>
                                    </Col>
                                    <Col xs={8} className="input-required">
                                      <div style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>Model</div>
                                    </Col>
                                    <Col xs={8} className="input-required">
                                      <div style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>Sold Unit</div>
                                    </Col>
                                  </Row>
                                </Col>
                                {values.car_sales.map((saleItem, index) => (
                                  <Col xs={24} key={`${index}-${saleItem._id}`}>
                                    <Row gutter={[4.0]}>
                                      <Col xs={8}>
                                        <Select
                                          onChange={id => {
                                            handleChangeBrand(id, index);
                                            handleCarSaleItemChange(
                                              values.car_sales,
                                              setFieldValue,
                                              index,
                                              id,
                                              'brand',
                                            );
                                          }}
                                          style={{ marginTop: '8px' }}
                                          defaultValue={saleItem.brand}
                                          value={saleItem.brand}
                                          placeholder="Choose car brand"
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                          }
                                          dropdownStyle={{ zIndex: 998 }}
                                        >
                                          {brands.map(brand => (
                                            <Select.Option value={brand._id} key={brand._id}>
                                              {brand.brand}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Col>
                                      <Col xs={8}>
                                        <Select
                                          placeholder="Choose car model"
                                          showSearch
                                          optionFilterProp="children"
                                          onChange={id =>
                                            handleCarSaleItemChange(values.car_sales, setFieldValue, index, id, 'model')
                                          }
                                          style={{ marginTop: '8px' }}
                                          defaultValue={saleItem.model}
                                          value={saleItem.model}
                                          filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                          }
                                          dropdownStyle={{ zIndex: 998 }}
                                        >
                                          {brand_models[index]?.map(model => (
                                            <Select.Option value={model._id} key={model._id}>
                                              {model.model}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Col>
                                      <Col xs={8}>
                                        <FormInput
                                          type="number"
                                          name={`car_sales.${index}.sold`}
                                          isRequired={false}
                                          label=""
                                          onChange={event =>
                                            handleCarSaleItemChange(
                                              values.car_sales,
                                              setFieldValue,
                                              index,
                                              event.target.value,
                                              'sold',
                                            )
                                          }
                                          value={saleItem.sold}
                                          errors={errors}
                                          touched={touched}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                              </Row>
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <div style={{ height: '16px' }} />
                    <Col xs={24}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          style={{
                            backgroundColor: '#155BDC',
                            color: '#fff',
                            marginRight: 5,
                            height: '40px',
                            fontWeight: '500',
                          }}
                          htmlType="submit"
                        >
                          Update Car Sale
                        </Button>
                        <Button
                          size="default"
                          type="white"
                          style={{
                            marginRight: 5,
                            height: '40px',
                            fontWeight: '500',
                          }}
                          key="back"
                          outlined="true"
                          onClick={() => {
                            resetForm();
                            onCancel();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </div>
                </BasicFormWrapper>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditTopCarSale;
