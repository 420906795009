/* eslint-disable no-underscore-dangle */
import { Col, Row, Spin } from 'antd';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import BlogForm from './BlogForm';
import { blogInitialFormData } from './blog.schema';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { getSingleBlog } from '../../../redux/blog/actionCreator';
import { getAllBlogCategories } from '../../../redux/blogcategory/actionCreator';
import { getAllUsers } from '../../../redux/users/actionCreator';
import { DEFAULT_LANG, IMAGE_URL, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import { Main } from '../../styled';
import '../Blog.css';

const EditBlog = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { blogsData, blogLoading, userType } = useSelector(state => {
    return {
      blogsData: state.blog.blogs,
      blogLoading: state.blog.loading,
      userType: state.auth.user.role.name,
    };
  });

  const [formData, setFormData] = useState({ ...blogInitialFormData });

  useEffect(() => {
    dispatch(getAllUsers('?role=writer'));
    getAllBlogCategories(dispatch);
    dispatch(getSingleBlog(match.params.id));
  }, []);

  useEffect(() => {
    if (blogsData && blogsData?.length > 0) {
      const blogDataById = blogsData.find(blog => blog?._id === match.params.id);
      if (blogDataById) {
        let apiResData = {
          _id: blogDataById?._id,
          featured: String(blogDataById?.featured),
          category: blogDataById?.category?._id,
          author: blogDataById?.author?._id,
          isMultiLang: blogDataById?.details && blogDataById?.details.length > 1 ? 'true' : 'false',
        };

        if (blogDataById?.image) {
          apiResData = {
            ...apiResData,
            image: [
              {
                uid: '-1',
                name: 'uploaded-file',
                status: 'done',
                url: `${IMAGE_URL}/${blogDataById?.image}`,
              },
            ],
          };
        }

        let titleAndDescFields = {};
        // eslint-disable-next-line no-unused-vars
        if (blogDataById?.details.length > 1) {
          LANGUAGE_BY_DOMAIN.forEach((langItem, index) => {
            titleAndDescFields = {
              ...titleAndDescFields,
              [`title${langItem[1]}`]: blogDataById?.details[index]?.title ?? '',
              [`description${langItem[1]}`]: blogDataById?.details[index]?.description ?? '',
            };
          });
        } else {
          titleAndDescFields = {
            ...titleAndDescFields,
            [`title${DEFAULT_LANG.value}`]: blogDataById?.details[0]?.title ?? '',
            [`description${DEFAULT_LANG.value}`]: blogDataById?.details[0]?.description ?? '',
          };
        }

        setFormData({
          ...formData,
          ...apiResData,
          ...titleAndDescFields,
        });
      }
    }
  }, [blogsData]);

  return blogLoading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <>
      <PageHeader
        ghost
        title="Edit Blog"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/${userType}/blogs`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={1} />
            <Col xs={22}>
              <div className="edit-blog">
                <BlogForm formData={formData} setFormData={setFormData} />
              </div>
            </Col>
            <Col xs={1} />
          </Row>
        </Cards>
      </Main>
    </>
  );
};

EditBlog.propTypes = {
  match: propTypes.string.isRequired,
};

export default EditBlog;
