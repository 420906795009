import { Col, Row } from 'antd';
import React from 'react';
import moment from 'moment';
//
import { Modal } from '../../components/modals/antd-modals';
import { Button } from '../../components/buttons/buttons';

const ValueComponent = ({ label, value }) => {
  return (
    <div>
      <div style={{ paddingBottom: '8px' }}>{label}</div>
      <div style={{ backgroundColor: '#E3E6EF', border: '1px solid #f5f5f5', padding: '12px 11px' }}>{value}</div>
    </div>
  );
};

const PreValueComponent = ({ label, value }) => {
  return (
    <div>
      <div style={{ paddingBottom: '8px' }}>{label}</div>
      <pre
        style={{ backgroundColor: '#E3E6EF', maxHeight: '500px', border: '1px solid #f5f5f5', padding: '12px 11px' }}
      >
        {value}
      </pre>
    </div>
  );
};

const ViewErrorLogModal = ({ visible, onCancel, errorLog }) => {
  return errorLog ? (
    <Modal
      visible={visible}
      type="primary"
      title={errorLog.message}
      onCancel={onCancel}
      footer={[
        <div key="view-error-log-modal" className="car-modal-footer">
          <Button size="default" type="white" key="black" outlined onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
    >
      <Row gutter={[12, 10]}>
        <Col xs={24}>
          <ValueComponent label="Service" value={errorLog.service} />
        </Col>
        <Col xs={24}>
          <ValueComponent label="Message" value={errorLog.message} />
        </Col>
        <Col xs={24}>
          <ValueComponent label="Severity" value={errorLog.severity} />
        </Col>
        <Col xs={24}>
          <ValueComponent label="Timestamp" value={moment(errorLog.timestamp).format('YYYY-MM-DD, h:mm:ss a')} />
        </Col>
        <Col xs={24}>
          <PreValueComponent label="Error Stack" value={JSON.stringify(errorLog.stackTrace, null, 2)} />
        </Col>
        <Col xs={24}>
          {errorLog?.requestPayload && (
            <PreValueComponent label="Request Payload" value={JSON.stringify(errorLog.requestPayload, null, 2)} />
          )}
        </Col>
      </Row>
    </Modal>
  ) : (
    <></>
  );
};

export default ViewErrorLogModal;
