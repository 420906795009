import { message } from 'antd';
//
import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import errorHandling from '../error';
import { getHeaders } from '../../utils/common';

const {
  getElectricityPricesBegin,
  getElectricityPricesFail,
  getElectricityPricesSuccess,
  addElectricityPriceBegin,
  addElectricityPriceFailed,
  addElectricityPriceSuccess,
  updateElectricityPriceBegin,
  updateElectricityPriceFailed,
  updateElectricityPriceSuccess,
  deleteElectricityPriceBegin,
  deleteElectricityPriceFailed,
  deleteElectricityPriceSuccess,
} = actions;

let cancelToken;
export const getElectricityPrices = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getElectricityPricesSuccess({ electricityPrices: [] }));
    dispatch(getElectricityPricesBegin());
    const res = await axios.get(`electricity-price${filter ? filter : ''}`, {
      cancelToken: cancelToken?.token,
      headers: { ...getHeaders() },
    });
    dispatch(
      getElectricityPricesSuccess({
        electricityPrices: res.data?.electricityPrices,
        total: res.data.total,
        currentPage: res.data.currentPage,
        pageLimit: res.data.pageLimit,
      }),
    );
    return res.data;
  } catch (err) {
    dispatch(getElectricityPricesFail(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

export const addElectricityPrice = formData => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addElectricityPriceBegin());
    const res = await axios.post('electricity-price', formData, {
      headers: { ...getHeaders() },
    });
    const newElectricityPrices = [res.data, ...state.electricityPrice.electricityPrices];
    dispatch(addElectricityPriceSuccess(newElectricityPrices));
    message.success('Electricity Price added successfully');
    return res;
  } catch (err) {
    dispatch(addElectricityPriceFailed(err?.response.data?.msg));
    dispatch(errorHandling(err));
  }
};

export const editElectricityPrice = (id, data) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateElectricityPriceBegin());
    const res = await axios.put(`electricity-price/${id}`, data, {
      headers: { ...getHeaders() },
    });
    const updatedElectricity = state.electricityPrice.electricityPrices.map(electricity => {
      if (electricity._id === id) return res.data;
      return electricity;
    });
    dispatch(updateElectricityPriceSuccess(updatedElectricity));
    message.success('Electricity Price updated successfully');
  } catch (err) {
    dispatch(updateElectricityPriceFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

export const deleteElectricityPrice = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteElectricityPriceBegin());
    await axios.delete(`electricity-price/${id}`, { headers: { ...getHeaders() } });
    dispatch(deleteElectricityPriceSuccess(state.electricityPrice.electricityPrices.filter(electricity => electricity._id !== id)));
    message.success('Electricity Price deleted successfully');
  } catch (err) {
    dispatch(deleteElectricityPriceFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};
