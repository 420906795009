import * as Yup from 'yup';

export const electricityPriceSchema = Yup.object().shape({
  effectiveDate: Yup.date('Effective Date must be a Valid Date').required('Effective Date is Required'),
  timeBasedPrices: Yup.array().of(
    Yup.object().shape({
      timing: Yup.string().required('Timing is Required'),
      price: Yup.number()
        .typeError('Price must be a number')
        .positive('Price must be greater then zero')
        .required('Price is Required'),
    }),
  ),
});
