import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FeatherIcon from 'feather-icons-react';
//
import { toolsPageInitialFormData } from './editToolsPage.schema';
import ToolsPageContentForm from './ToolsPageContentForm';
import { getToolsPageContent } from '../../../redux/toolsPagesContent/actionCreator';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Button } from '../../../components/buttons/buttons';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';

const EditToolsPageContent = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentToolPageContent, loading } = useSelector(state => {
    return {
      currentToolPageContent: state.toolsPagesContent.currentToolPageContent,
      loading: state.toolsPagesContent.loading,
    };
  });

  const [formData, setFormData] = useState({ ...toolsPageInitialFormData });

  useEffect(() => {
    if (match.params.destination) {
      dispatch(getToolsPageContent(match.params.destination));
    }
  }, [match.params.destination]);

  useEffect(() => {
    if (currentToolPageContent) {
      const isMultiLang = currentToolPageContent.details?.length > 1;
      const transformedContent = currentToolPageContent.details.reduce(
        (prev, current) => ({
          ...prev,
          [`description${current.lang}`]: current.description,
        }),
        {},
      );
      const tempFormData = {
        isMultiLang: String(isMultiLang),
        name: currentToolPageContent.name,
        ...transformedContent,
      };
      setFormData(tempFormData);
    }
  }, [currentToolPageContent]);

  return loading || !currentToolPageContent ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <>
      <PageHeader
        ghost
        title="Edit Tool Page Details"
        buttons={[
          <Button
            key="1"
            style={{ backgroundColor: 'grey', color: '#fff' }}
            size="default"
            onClick={() => history.push(`/admin/tools-page-content`)}
          >
            <FeatherIcon icon="chevron-left" size={16} /> Back to List
          </Button>,
        ]}
      />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={1} />
            <Col xs={22}>
              <div className="edit-blog">
                <ToolsPageContentForm
                  formData={formData}
                  setFormData={setFormData}
                  destination={match.params.destination}
                />
              </div>
            </Col>
            <Col xs={1} />
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default EditToolsPageContent;
