import React from 'react';
import { Button, Col, Modal, Row } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { FieldArray, Formik, Form } from 'formik';
import { BasicFormWrapper } from '../styled';
import FormInput from '../../components/input/input';
import { formatDate } from '../../utility/utility';
//

const EditFuelPriceModal = ({ visible, onCancel, handleEdit, priceValue, fuelPriceId }) => {
  const handleFuelItemChange = (fieldFuelPrices, setFieldValues, index, value, key) => {
    const temp = [...fieldFuelPrices];
    if (!temp[index]) return;
    const currentItem = JSON.parse(JSON.stringify(temp[index]));
    currentItem[key] = value;
    temp[index] = currentItem;
    setFieldValues('fuelPrices', temp);
  };
  return (
    <Modal type="primary" title="Create Fuel Price" footer={null} onCancel={onCancel} visible={visible}>
      <div className="brand-modal">
        <Formik initialValues={priceValue} onSubmit={formData => handleEdit(fuelPriceId, formData)}>
          {({ values, errors, touched, handleChange, setFieldValue, resetForm }) => {
            return (
              <Form>
                <BasicFormWrapper>
                  <div className="add-product-block">
                    <Col xs={24}>
                      <FormInput
                        type="date"
                        name="effectiveDate"
                        label="Effective Date"
                        value={formatDate(values.effectiveDate)}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col xs={24}>
                      <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <FieldArray
                          name="fuelPrices"
                          render={arrayHelper => {
                            return (
                              <Row gutter={[0, 8]} style={{ marginTop: '16px' }}>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={8} className="input-required">
                                      <div style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>Fuel Price</div>
                                    </Col>
                                    <Col xs={6} className="input-required">
                                      <div style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>InLand Price</div>
                                    </Col>
                                    <Col xs={6} className="input-required">
                                      <div style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>Coastal Price</div>
                                    </Col>
                                  </Row>
                                </Col>
                                {values.fuelPrices.map((price, index) => (
                                  <Col xs={24} key={`${index}-${price.id}`}>
                                    <Row gutter={[4, 0]}>
                                      <Col xs={8}>
                                        <FormInput
                                          name={`fuelPrices.${index}.fuelType`}
                                          placeholder="Please enter Fuel Type"
                                          isRequired={false}
                                          value={price.fuelType}
                                          errors={errors}
                                          touched={touched}
                                          onChange={event =>
                                            handleFuelItemChange(
                                              values.fuelPrices,
                                              setFieldValue,
                                              index,
                                              event.target.value,
                                              'fuelType',
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col xs={6}>
                                        <FormInput
                                          name={`fuelPrices.${index}.inlandPrice`}
                                          placeholder="Please enter Inland Price"
                                          type="number"
                                          isRequired={false}
                                          value={price.inlandPrice}
                                          errors={errors}
                                          touched={touched}
                                          onChange={event =>
                                            handleFuelItemChange(
                                              values.fuelPrices,
                                              setFieldValue,
                                              index,
                                              event.target.value,
                                              'inlandPrice',
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col xs={6}>
                                        <FormInput
                                          name={`fuelPrices.${index}.coastalPrice`}
                                          placeholder="Please enter Coastal Price"
                                          isRequired={false}
                                          type="number"
                                          value={price.coastalPrice}
                                          errors={errors}
                                          touched={touched}
                                          onChange={event =>
                                            handleFuelItemChange(
                                              values.fuelPrices,
                                              setFieldValue,
                                              index,
                                              event.target.value,
                                              'coastalPrice',
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col xs={2} style={{ display: 'flex', alignItems: 'end' }}>
                                        <Button
                                          className=""
                                          disabled={values.fuelPrices.length === 1}
                                          style={{ margin: '4px' }}
                                          onClick={() => arrayHelper.remove(index)}
                                          shape="circle"
                                          icon={<FeatherIcon icon="minus-circle" size={10} />}
                                        />
                                      </Col>
                                      <Col xs={2} style={{ display: 'flex', alignItems: 'end' }}>
                                        {index === values.fuelPrices.length - 1 && (
                                          <Button
                                            style={{ margin: '4px' }}
                                            onClick={() =>
                                              arrayHelper.push({
                                                fuelType: '',
                                                inlandPrice: '',
                                                coastalPrice: '',
                                              })
                                            }
                                            shape="circle"
                                            icon={<FeatherIcon icon="plus-circle" size={10} />}
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                              </Row>
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <div style={{ height: '16px' }} />
                    <Col xs={24}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          style={{
                            backgroundColor: '#155BDC',
                            color: '#fff',
                            marginRight: 5,
                            height: '40px',
                            fontWeight: '500',
                          }}
                          htmlType="submit"
                        >
                          Update Fuel Price
                        </Button>
                        <Button
                          size="default"
                          type="white"
                          style={{
                            marginRight: 5,
                            height: '40px',
                            fontWeight: '500',
                          }}
                          key="back"
                          outlined="true"
                          onClick={() => {
                            resetForm();
                            onCancel();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </div>
                </BasicFormWrapper>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditFuelPriceModal;
