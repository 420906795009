import React from 'react';
import { Modal } from '../../components/modals/antd-modals';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../components/buttons/buttons';

const DeleteTechnicalDocsModal = ({ visible, onCancel, handleDelete, technicalDoc }) => {
  return technicalDoc ? (
    <Modal
      visible={visible}
      type="primary"
      title="Delete Technical Document?"
      onCancel={onCancel}
      footer={[
        <div key="delete-technical-doc" className="car-modal-footer">
          <Button size="default" type="danger" key="submit" onClick={() => handleDelete(technicalDoc._id)}>
            Delete Document
          </Button>
          <Button size="default" type="white" key="black" outlined="true" onClick={onCancel}>
            Cancel
          </Button>
        </div>,
      ]}
    >
      <div className="car-modal">
        <BasicFormWrapper>
          <p>Delete Technical Document ?</p>
        </BasicFormWrapper>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default DeleteTechnicalDocsModal;
