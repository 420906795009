import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Col, Row, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { toolsPageValidationSchema } from './editToolsPage.schema';
import FormInput from '../../../components/input/input';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import DivBreak from '../../../components/divBreak/DivBreak';
import { Button } from '../../../components/buttons/buttons';
import { updateToolsPageContent } from '../../../redux/toolsPagesContent/actionCreator';

const ToolsPageContentForm = props => {
  const { formData, setFormData } = props;
  const dispatch = useDispatch();

  const [isMultiLang, setIsMultiLangFlag] = useState(false);

  async function handleFormSubmit(requestData, resetForm) {
    try {
      let apiReqData = {
        name: requestData.name,
        destination: props.destination,
      };
      const transformedContent = [];
      LANGUAGE_BY_DOMAIN.forEach(langItem => {
        if (requestData.isMultiLang === 'true' || langItem[1] === DEFAULT_LANG.value) {
          transformedContent.push({
            lang: langItem[1],
            description: requestData[`description${langItem[1]}`],
          });
        }
      });
      apiReqData = {
        ...apiReqData,
        details: JSON.stringify(transformedContent),
      };
      const res = await dispatch(updateToolsPageContent(props.destination, apiReqData));
      if (res?.data?.status) {
        resetForm();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (formData?.isMultiLang === 'true') setIsMultiLangFlag(true);
  }, [formData]);

  return (
    <Formik
      initialValues={formData}
      validationSchema={toolsPageValidationSchema(isMultiLang)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        handleFormSubmit(values, resetForm);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Form>
            <div className="add-product-block">
              <Row gutter={[16, 16]}>
                {/* language switcher */}
                <Col xs={24}>
                  <h4>Translate</h4>
                  <Switch
                    checked={values.isMultiLang === 'true'}
                    onChange={checked => {
                      setFieldValue('isMultiLang', String(checked));
                      setIsMultiLangFlag(checked);
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <FormInput
                    name="name"
                    label="Name"
                    value={values.name}
                    placeholder="Name"
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col xs={24}>
                  <FormInput
                    type="editor"
                    name={`description${DEFAULT_LANG.value}`}
                    label={`Details (${DEFAULT_LANG.label})`}
                    editorState={values[`description${DEFAULT_LANG.value}`]}
                    handleEditorStateChange={state => {
                      const body = `description${DEFAULT_LANG.value}`;
                      setFieldValue(body, state);
                    }}
                    placeholder="Details"
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                {values.isMultiLang === 'true' &&
                  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                    return (
                      <Col xs={24} key={index}>
                        <FormInput
                          type="editor"
                          name={`description${value}`}
                          label={`Details (${label})`}
                          editorState={values[`description${value}`] || ''}
                          handleEditorStateChange={state => {
                            const body = `description${value}`;
                            setFieldValue(body, state);
                          }}
                          placeholder="Details"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </div>
            <DivBreak />
            <Row justify="end" align="middle">
              <Col>
                <Button
                  style={{
                    backgroundColor: '#155BDC',
                    color: '#fff',
                    marginRight: 5,
                    height: '40px',
                    fontWeight: '500',
                  }}
                  htmlType="submit"
                >
                  Update Content
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ToolsPageContentForm;
